const SERVICE_NAME = "child-history";
import backend from "@/lib/backend";
export default axios => ({
  getHistory(options) {
    return backend(axios, SERVICE_NAME).find(options);
  },
  find(options) {
    return backend(axios, SERVICE_NAME).find(options);
  }
});
