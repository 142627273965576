
export default {
  props: {
    horizontal: {
      default: false
    },
    customAds: {
      type: Object
    }
  },
  async mounted() {
    console.log('custom ad mounted - item id ', this.customAds.adItemId);
    this.$api.advertisement.create('ad-seen', {
      adItemId: this.customAds.adItemId
    });
  }
};
