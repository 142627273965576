const SERVICE_NAME = "image-upload";
import backend from "@/lib/backend";
export default axios => ({
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
  delete(id) {
    return backend(axios, SERVICE_NAME).delete(id);
  }
});
