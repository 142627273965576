export const Ads = () => import('../../components/Ads.vue' /* webpackChunkName: "components/ads" */).then(c => wrapFunctional(c.default || c))
export const AskConfirmation = () => import('../../components/AskConfirmation.vue' /* webpackChunkName: "components/ask-confirmation" */).then(c => wrapFunctional(c.default || c))
export const AskDoubt = () => import('../../components/AskDoubt.vue' /* webpackChunkName: "components/ask-doubt" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CountryFlag = () => import('../../components/CountryFlag.vue' /* webpackChunkName: "components/country-flag" */).then(c => wrapFunctional(c.default || c))
export const CouponUsageUser = () => import('../../components/CouponUsageUser.vue' /* webpackChunkName: "components/coupon-usage-user" */).then(c => wrapFunctional(c.default || c))
export const CustomKeyboard = () => import('../../components/CustomKeyboard.vue' /* webpackChunkName: "components/custom-keyboard" */).then(c => wrapFunctional(c.default || c))
export const CustomPagination = () => import('../../components/CustomPagination.vue' /* webpackChunkName: "components/custom-pagination" */).then(c => wrapFunctional(c.default || c))
export const DateTimer = () => import('../../components/DateTimer.vue' /* webpackChunkName: "components/date-timer" */).then(c => wrapFunctional(c.default || c))
export const EmojiPicker = () => import('../../components/EmojiPicker.vue' /* webpackChunkName: "components/emoji-picker" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GoogleAds = () => import('../../components/GoogleAds.vue' /* webpackChunkName: "components/google-ads" */).then(c => wrapFunctional(c.default || c))
export const HyperlinkPagination = () => import('../../components/HyperlinkPagination.vue' /* webpackChunkName: "components/hyperlink-pagination" */).then(c => wrapFunctional(c.default || c))
export const ImageDropBox = () => import('../../components/ImageDropBox.vue' /* webpackChunkName: "components/image-drop-box" */).then(c => wrapFunctional(c.default || c))
export const ImagePicker = () => import('../../components/ImagePicker.vue' /* webpackChunkName: "components/image-picker" */).then(c => wrapFunctional(c.default || c))
export const MixTypeAssesment = () => import('../../components/MixTypeAssesment.vue' /* webpackChunkName: "components/mix-type-assesment" */).then(c => wrapFunctional(c.default || c))
export const PricingCard = () => import('../../components/PricingCard.vue' /* webpackChunkName: "components/pricing-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileFrame = () => import('../../components/ProfileFrame.vue' /* webpackChunkName: "components/profile-frame" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ProgressBarModal = () => import('../../components/ProgressBarModal.vue' /* webpackChunkName: "components/progress-bar-modal" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../components/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SignInSignUp = () => import('../../components/SignInSignUp.vue' /* webpackChunkName: "components/sign-in-sign-up" */).then(c => wrapFunctional(c.default || c))
export const SignInSignUpOri = () => import('../../components/SignInSignUpOri.vue' /* webpackChunkName: "components/sign-in-sign-up-ori" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../components/SignUp.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const Snackbar = () => import('../../components/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c))
export const SubscriberOnly = () => import('../../components/SubscriberOnly.vue' /* webpackChunkName: "components/subscriber-only" */).then(c => wrapFunctional(c.default || c))
export const VideoPicker = () => import('../../components/VideoPicker.vue' /* webpackChunkName: "components/video-picker" */).then(c => wrapFunctional(c.default || c))
export const ChatHistory = () => import('../../components/chatHistory.vue' /* webpackChunkName: "components/chat-history" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../components/confirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialogNew = () => import('../../components/confirmDialogNew.vue' /* webpackChunkName: "components/confirm-dialog-new" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountFromPaytm = () => import('../../components/createAccountFromPaytm.vue' /* webpackChunkName: "components/create-account-from-paytm" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountNew1 = () => import('../../components/createAccountNew1.vue' /* webpackChunkName: "components/create-account-new1" */).then(c => wrapFunctional(c.default || c))
export const CreateTag = () => import('../../components/createTag.vue' /* webpackChunkName: "components/create-tag" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const FullScreenLoader = () => import('../../components/fullScreenLoader.vue' /* webpackChunkName: "components/full-screen-loader" */).then(c => wrapFunctional(c.default || c))
export const InfoBar = () => import('../../components/infoBar.vue' /* webpackChunkName: "components/info-bar" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const NoChild = () => import('../../components/noChild.vue' /* webpackChunkName: "components/no-child" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ProfileBanner = () => import('../../components/profileBanner.vue' /* webpackChunkName: "components/profile-banner" */).then(c => wrapFunctional(c.default || c))
export const QuestionList = () => import('../../components/question-list.vue' /* webpackChunkName: "components/question-list" */).then(c => wrapFunctional(c.default || c))
export const QuestionSetList = () => import('../../components/questionSetList.vue' /* webpackChunkName: "components/question-set-list" */).then(c => wrapFunctional(c.default || c))
export const SelectQuestionType = () => import('../../components/selectQuestionType.vue' /* webpackChunkName: "components/select-question-type" */).then(c => wrapFunctional(c.default || c))
export const UserUpdateDetails = () => import('../../components/userUpdateDetails.vue' /* webpackChunkName: "components/user-update-details" */).then(c => wrapFunctional(c.default || c))
export const AboutUsHow = () => import('../../components/AboutUs/How.vue' /* webpackChunkName: "components/about-us-how" */).then(c => wrapFunctional(c.default || c))
export const AboutUsTitle = () => import('../../components/AboutUs/Title.vue' /* webpackChunkName: "components/about-us-title" */).then(c => wrapFunctional(c.default || c))
export const AboutUsWhat = () => import('../../components/AboutUs/What.vue' /* webpackChunkName: "components/about-us-what" */).then(c => wrapFunctional(c.default || c))
export const AboutUsWho = () => import('../../components/AboutUs/Who.vue' /* webpackChunkName: "components/about-us-who" */).then(c => wrapFunctional(c.default || c))
export const AboutUsWhy = () => import('../../components/AboutUs/Why.vue' /* webpackChunkName: "components/about-us-why" */).then(c => wrapFunctional(c.default || c))
export const AdvertisementAnalytics = () => import('../../components/Advertisement/Analytics.vue' /* webpackChunkName: "components/advertisement-analytics" */).then(c => wrapFunctional(c.default || c))
export const BoxPuzzle = () => import('../../components/BoxPuzzle/BoxPuzzle.vue' /* webpackChunkName: "components/box-puzzle" */).then(c => wrapFunctional(c.default || c))
export const BoxPuzzleWidget = () => import('../../components/BoxPuzzle/BoxPuzzleWidget.vue' /* webpackChunkName: "components/box-puzzle-widget" */).then(c => wrapFunctional(c.default || c))
export const BoxPuzzleNumberField = () => import('../../components/BoxPuzzle/NumberField.vue' /* webpackChunkName: "components/box-puzzle-number-field" */).then(c => wrapFunctional(c.default || c))
export const BoxPuzzleCreateBoxPuzzle = () => import('../../components/BoxPuzzle/createBoxPuzzle.vue' /* webpackChunkName: "components/box-puzzle-create-box-puzzle" */).then(c => wrapFunctional(c.default || c))
export const BoxTypeCreateBoxTypeQuestion = () => import('../../components/BoxType/createBoxTypeQuestion.vue' /* webpackChunkName: "components/box-type-create-box-type-question" */).then(c => wrapFunctional(c.default || c))
export const BoxTypeEditBoxTypeQuestion = () => import('../../components/BoxType/editBoxTypeQuestion.vue' /* webpackChunkName: "components/box-type-edit-box-type-question" */).then(c => wrapFunctional(c.default || c))
export const CalculatorQuestionsCreateCalculatorQuestion = () => import('../../components/CalculatorQuestions/createCalculatorQuestion.vue' /* webpackChunkName: "components/calculator-questions-create-calculator-question" */).then(c => wrapFunctional(c.default || c))
export const CalculatorQuestionsEditCalculatorQuestion = () => import('../../components/CalculatorQuestions/editCalculatorQuestion.vue' /* webpackChunkName: "components/calculator-questions-edit-calculator-question" */).then(c => wrapFunctional(c.default || c))
export const ChallengesCompetitionsDialog = () => import('../../components/Challenges/CompetitionsDialog.vue' /* webpackChunkName: "components/challenges-competitions-dialog" */).then(c => wrapFunctional(c.default || c))
export const ChallengesCompetitionsPreview = () => import('../../components/Challenges/CompetitionsPreview.vue' /* webpackChunkName: "components/challenges-competitions-preview" */).then(c => wrapFunctional(c.default || c))
export const ChallengesHeader = () => import('../../components/Challenges/Header.vue' /* webpackChunkName: "components/challenges-header" */).then(c => wrapFunctional(c.default || c))
export const ChallengesProfileFrame = () => import('../../components/Challenges/ProfileFrame.vue' /* webpackChunkName: "components/challenges-profile-frame" */).then(c => wrapFunctional(c.default || c))
export const ChallengesTrendingPreview = () => import('../../components/Challenges/TrendingPreview.vue' /* webpackChunkName: "components/challenges-trending-preview" */).then(c => wrapFunctional(c.default || c))
export const ChallengesWinnersPreview = () => import('../../components/Challenges/WinnersPreview.vue' /* webpackChunkName: "components/challenges-winners-preview" */).then(c => wrapFunctional(c.default || c))
export const ChatAddParticipants = () => import('../../components/Chat/AddParticipants.vue' /* webpackChunkName: "components/chat-add-participants" */).then(c => wrapFunctional(c.default || c))
export const ChatAskFriend = () => import('../../components/Chat/AskFriend.vue' /* webpackChunkName: "components/chat-ask-friend" */).then(c => wrapFunctional(c.default || c))
export const ChatFriendsList = () => import('../../components/Chat/FriendsList.vue' /* webpackChunkName: "components/chat-friends-list" */).then(c => wrapFunctional(c.default || c))
export const ChatGroup = () => import('../../components/Chat/Group.vue' /* webpackChunkName: "components/chat-group" */).then(c => wrapFunctional(c.default || c))
export const ChatProfileInfo = () => import('../../components/Chat/ProfileInfo.vue' /* webpackChunkName: "components/chat-profile-info" */).then(c => wrapFunctional(c.default || c))
export const ChatStartChat = () => import('../../components/Chat/StartChat.vue' /* webpackChunkName: "components/chat-start-chat" */).then(c => wrapFunctional(c.default || c))
export const ClockTypeQuestionCreateClockTypeQuestion = () => import('../../components/ClockTypeQuestion/createClockTypeQuestion.vue' /* webpackChunkName: "components/clock-type-question-create-clock-type-question" */).then(c => wrapFunctional(c.default || c))
export const ClockTypeQuestionEditClockTypeQuestion = () => import('../../components/ClockTypeQuestion/editClockTypeQuestion.vue' /* webpackChunkName: "components/clock-type-question-edit-clock-type-question" */).then(c => wrapFunctional(c.default || c))
export const CrosswordGridPuzzle = () => import('../../components/Crossword/GridPuzzle.vue' /* webpackChunkName: "components/crossword-grid-puzzle" */).then(c => wrapFunctional(c.default || c))
export const CrosswordCreateCrosswordQuestion = () => import('../../components/Crossword/createCrosswordQuestion.vue' /* webpackChunkName: "components/crossword-create-crossword-question" */).then(c => wrapFunctional(c.default || c))
export const DigitTypeCreateDigitTypeQuestion = () => import('../../components/DigitType/createDigitTypeQuestion.vue' /* webpackChunkName: "components/digit-type-create-digit-type-question" */).then(c => wrapFunctional(c.default || c))
export const DigitTypeEditDigitTypeQuestion = () => import('../../components/DigitType/editDigitTypeQuestion.vue' /* webpackChunkName: "components/digit-type-edit-digit-type-question" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreAsset = () => import('../../components/DigitalStore/Asset.vue' /* webpackChunkName: "components/digital-store-asset" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreBag = () => import('../../components/DigitalStore/Bag.vue' /* webpackChunkName: "components/digital-store-bag" */).then(c => wrapFunctional(c.default || c))
export const DigitalItems = () => import('../../components/DigitalStore/DigitalItems.vue' /* webpackChunkName: "components/digital-items" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreProductView = () => import('../../components/DigitalStore/ProductView.vue' /* webpackChunkName: "components/digital-store-product-view" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreProfilePicture = () => import('../../components/DigitalStore/ProfilePicture.vue' /* webpackChunkName: "components/digital-store-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreTradeInfo = () => import('../../components/DigitalStore/TradeInfo.vue' /* webpackChunkName: "components/digital-store-trade-info" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreTradePoints = () => import('../../components/DigitalStore/TradePoints.vue' /* webpackChunkName: "components/digital-store-trade-points" */).then(c => wrapFunctional(c.default || c))
export const DigitalStoreTradeView = () => import('../../components/DigitalStore/TradeView.vue' /* webpackChunkName: "components/digital-store-trade-view" */).then(c => wrapFunctional(c.default || c))
export const FillInTheBlankCreateFillInTheBlankQuestion = () => import('../../components/FillInTheBlank/createFillInTheBlankQuestion.vue' /* webpackChunkName: "components/fill-in-the-blank-create-fill-in-the-blank-question" */).then(c => wrapFunctional(c.default || c))
export const FillInTheBlankEditFillInTheBlankQuestion = () => import('../../components/FillInTheBlank/editFillInTheBlankQuestion.vue' /* webpackChunkName: "components/fill-in-the-blank-edit-fill-in-the-blank-question" */).then(c => wrapFunctional(c.default || c))
export const GPQGridPuzzle = () => import('../../components/GPQ/GridPuzzle.vue' /* webpackChunkName: "components/g-p-q-grid-puzzle" */).then(c => wrapFunctional(c.default || c))
export const GPQGridPuzzleAdmin = () => import('../../components/GPQ/GridPuzzleAdmin.vue' /* webpackChunkName: "components/g-p-q-grid-puzzle-admin" */).then(c => wrapFunctional(c.default || c))
export const GPQCreateGridPuzzleQuestion = () => import('../../components/GPQ/createGridPuzzleQuestion.vue' /* webpackChunkName: "components/g-p-q-create-grid-puzzle-question" */).then(c => wrapFunctional(c.default || c))
export const GPQHelper = () => import('../../components/GPQ/helper.js' /* webpackChunkName: "components/g-p-q-helper" */).then(c => wrapFunctional(c.default || c))
export const HowItWorksTimeline = () => import('../../components/HowItWorksTimeline/HowItWorksTimeline.vue' /* webpackChunkName: "components/how-it-works-timeline" */).then(c => wrapFunctional(c.default || c))
export const HowItWorksTimelineImage = () => import('../../components/HowItWorksTimeline/TimelineImage.vue' /* webpackChunkName: "components/how-it-works-timeline-image" */).then(c => wrapFunctional(c.default || c))
export const HowItWorksTimelineText = () => import('../../components/HowItWorksTimeline/TimelineText.vue' /* webpackChunkName: "components/how-it-works-timeline-text" */).then(c => wrapFunctional(c.default || c))
export const IdentifyWrongWordCreateCorrectTheSentence = () => import('../../components/IdentifyWrongWord/createCorrectTheSentence.vue' /* webpackChunkName: "components/identify-wrong-word-create-correct-the-sentence" */).then(c => wrapFunctional(c.default || c))
export const IdentifyWrongWordEditCorrectTheSentence = () => import('../../components/IdentifyWrongWord/editCorrectTheSentence.vue' /* webpackChunkName: "components/identify-wrong-word-edit-correct-the-sentence" */).then(c => wrapFunctional(c.default || c))
export const LessonAddLessonToQuestion = () => import('../../components/Lesson/AddLessonToQuestion.vue' /* webpackChunkName: "components/lesson-add-lesson-to-question" */).then(c => wrapFunctional(c.default || c))
export const LessonAddTopicDialog = () => import('../../components/Lesson/AddTopicDialog.vue' /* webpackChunkName: "components/lesson-add-topic-dialog" */).then(c => wrapFunctional(c.default || c))
export const LessonPlayer = () => import('../../components/Lesson/LessonPlayer.vue' /* webpackChunkName: "components/lesson-player" */).then(c => wrapFunctional(c.default || c))
export const LessonSelecteExistingLesson = () => import('../../components/Lesson/SelecteExistingLesson.vue' /* webpackChunkName: "components/lesson-selecte-existing-lesson" */).then(c => wrapFunctional(c.default || c))
export const LessonUploadLessonDialog = () => import('../../components/Lesson/UploadLessonDialog.vue' /* webpackChunkName: "components/lesson-upload-lesson-dialog" */).then(c => wrapFunctional(c.default || c))
export const LessonVideoPreview = () => import('../../components/Lesson/VideoPreview.vue' /* webpackChunkName: "components/lesson-video-preview" */).then(c => wrapFunctional(c.default || c))
export const LessonsIcon = () => import('../../components/Lessons/Icon.vue' /* webpackChunkName: "components/lessons-icon" */).then(c => wrapFunctional(c.default || c))
export const MCQMCQSingleChoice = () => import('../../components/MCQ/MCQSingleChoice.vue' /* webpackChunkName: "components/m-c-q-m-c-q-single-choice" */).then(c => wrapFunctional(c.default || c))
export const MCQCreateMCQQuestion = () => import('../../components/MCQ/createMCQQuestion.vue' /* webpackChunkName: "components/m-c-q-create-m-c-q-question" */).then(c => wrapFunctional(c.default || c))
export const MCQCreateMultiSelectMCQQuestion = () => import('../../components/MCQ/createMultiSelectMCQQuestion.vue' /* webpackChunkName: "components/m-c-q-create-multi-select-m-c-q-question" */).then(c => wrapFunctional(c.default || c))
export const MCQEditMCQQuestion = () => import('../../components/MCQ/editMCQQuestion.vue' /* webpackChunkName: "components/m-c-q-edit-m-c-q-question" */).then(c => wrapFunctional(c.default || c))
export const MCQEditMultiSelectMCQQuestion = () => import('../../components/MCQ/editMultiSelectMCQQuestion.vue' /* webpackChunkName: "components/m-c-q-edit-multi-select-m-c-q-question" */).then(c => wrapFunctional(c.default || c))
export const MatchTheFollowingTypesCreateMatchTheFollowingOneToMany = () => import('../../components/MatchTheFollowingTypes/createMatchTheFollowingOneToMany.vue' /* webpackChunkName: "components/match-the-following-types-create-match-the-following-one-to-many" */).then(c => wrapFunctional(c.default || c))
export const MatchTheFollowingTypesCreateMatchTheFollowingOneToOne = () => import('../../components/MatchTheFollowingTypes/createMatchTheFollowingOneToOne.vue' /* webpackChunkName: "components/match-the-following-types-create-match-the-following-one-to-one" */).then(c => wrapFunctional(c.default || c))
export const MiscDiscountCodeCountDown = () => import('../../components/Misc/DiscountCodeCountDown.vue' /* webpackChunkName: "components/misc-discount-code-count-down" */).then(c => wrapFunctional(c.default || c))
export const MiscLeadershipTable = () => import('../../components/Misc/LeadershipTable.vue' /* webpackChunkName: "components/misc-leadership-table" */).then(c => wrapFunctional(c.default || c))
export const QuestionSetFinderSearch = () => import('../../components/QuestionSetFinder/Search.vue' /* webpackChunkName: "components/question-set-finder-search" */).then(c => wrapFunctional(c.default || c))
export const QuestionSetView = () => import('../../components/QuestionSetFinder/SetView.vue' /* webpackChunkName: "components/question-set-view" */).then(c => wrapFunctional(c.default || c))
export const RearrangeBoxes = () => import('../../components/Rearrange/rearrange-boxes.vue' /* webpackChunkName: "components/rearrange-boxes" */).then(c => wrapFunctional(c.default || c))
export const S3Upload = () => import('../../components/S3Upload/S3Upload.vue' /* webpackChunkName: "components/s3-upload" */).then(c => wrapFunctional(c.default || c))
export const S3UploadUploadView = () => import('../../components/S3Upload/UploadView.vue' /* webpackChunkName: "components/s3-upload-upload-view" */).then(c => wrapFunctional(c.default || c))
export const ShopBreadcrumbs = () => import('../../components/Shop/Breadcrumbs.vue' /* webpackChunkName: "components/shop-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ShopProductView = () => import('../../components/Shop/ProductView.vue' /* webpackChunkName: "components/shop-product-view" */).then(c => wrapFunctional(c.default || c))
export const ShopHomeMetaTags = () => import('../../components/Shop/ShopHomeMetaTags.vue' /* webpackChunkName: "components/shop-home-meta-tags" */).then(c => wrapFunctional(c.default || c))
export const StoriesAskConfirmation = () => import('../../components/Stories/AskConfirmation.vue' /* webpackChunkName: "components/stories-ask-confirmation" */).then(c => wrapFunctional(c.default || c))
export const StoriesInformation = () => import('../../components/Stories/Information.vue' /* webpackChunkName: "components/stories-information" */).then(c => wrapFunctional(c.default || c))
export const StoriesSoundPicker = () => import('../../components/Stories/SoundPicker.vue' /* webpackChunkName: "components/stories-sound-picker" */).then(c => wrapFunctional(c.default || c))
export const StoriesStoryCharacters = () => import('../../components/Stories/StoryCharacters.vue' /* webpackChunkName: "components/stories-story-characters" */).then(c => wrapFunctional(c.default || c))
export const StoriesStoryView = () => import('../../components/Stories/StoryView.vue' /* webpackChunkName: "components/stories-story-view" */).then(c => wrapFunctional(c.default || c))
export const StoriesStoryline = () => import('../../components/Stories/Storyline.vue' /* webpackChunkName: "components/stories-storyline" */).then(c => wrapFunctional(c.default || c))
export const TableTypeNumberField = () => import('../../components/TableType/NumberField.vue' /* webpackChunkName: "components/table-type-number-field" */).then(c => wrapFunctional(c.default || c))
export const TableCell = () => import('../../components/TableType/TableCell.vue' /* webpackChunkName: "components/table-cell" */).then(c => wrapFunctional(c.default || c))
export const TableOfQuestions = () => import('../../components/TableType/TableOfQuestions.vue' /* webpackChunkName: "components/table-of-questions" */).then(c => wrapFunctional(c.default || c))
export const TableTypeCreateTableTypeQuestion = () => import('../../components/TableType/createTableTypeQuestion.vue' /* webpackChunkName: "components/table-type-create-table-type-question" */).then(c => wrapFunctional(c.default || c))
export const ArchivePointsDownloadBulkData = () => import('../../components/archive-points/DownloadBulkData.vue' /* webpackChunkName: "components/archive-points-download-bulk-data" */).then(c => wrapFunctional(c.default || c))
export const ArchivePointsNewArchiveSet = () => import('../../components/archive-points/NewArchiveSet.vue' /* webpackChunkName: "components/archive-points-new-archive-set" */).then(c => wrapFunctional(c.default || c))
export const AssessmentSet = () => import('../../components/assessment/AssessmentSet.vue' /* webpackChunkName: "components/assessment-set" */).then(c => wrapFunctional(c.default || c))
export const AssessmentLevelsEarned = () => import('../../components/assessment/LevelsEarned.vue' /* webpackChunkName: "components/assessment-levels-earned" */).then(c => wrapFunctional(c.default || c))
export const ChildProfileMyReport = () => import('../../components/childProfile/MyReport.vue' /* webpackChunkName: "components/child-profile-my-report" */).then(c => wrapFunctional(c.default || c))
export const CustomAds = () => import('../../components/customAds/ads.vue' /* webpackChunkName: "components/custom-ads" */).then(c => wrapFunctional(c.default || c))
export const CustomAdsNonImageAds = () => import('../../components/customAds/nonImageAds.vue' /* webpackChunkName: "components/custom-ads-non-image-ads" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsBadgesEarned = () => import('../../components/daily-questions/BadgesEarned.vue' /* webpackChunkName: "components/daily-questions-badges-earned" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsChooseSubject = () => import('../../components/daily-questions/ChooseSubject.vue' /* webpackChunkName: "components/daily-questions-choose-subject" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionAttendStatus = () => import('../../components/daily-questions/DailyQuestionAttendStatus.vue' /* webpackChunkName: "components/daily-question-attend-status" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionCountDown = () => import('../../components/daily-questions/DailyQuestionCountDown.vue' /* webpackChunkName: "components/daily-question-count-down" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestions = () => import('../../components/daily-questions/DailyQuestions.vue' /* webpackChunkName: "components/daily-questions" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsOpportunitiesViewOld = () => import('../../components/daily-questions/OpportunitiesViewOld.vue' /* webpackChunkName: "components/daily-questions-opportunities-view-old" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsPointsEarned = () => import('../../components/daily-questions/PointsEarned.vue' /* webpackChunkName: "components/daily-questions-points-earned" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsSubjectList = () => import('../../components/daily-questions/SubjectList.vue' /* webpackChunkName: "components/daily-questions-subject-list" */).then(c => wrapFunctional(c.default || c))
export const GiftClaim = () => import('../../components/gift/claim.vue' /* webpackChunkName: "components/gift-claim" */).then(c => wrapFunctional(c.default || c))
export const GiftReferral = () => import('../../components/gift/referral.vue' /* webpackChunkName: "components/gift-referral" */).then(c => wrapFunctional(c.default || c))
export const HomeChoosePlan = () => import('../../components/home/ChoosePlan.vue' /* webpackChunkName: "components/home-choose-plan" */).then(c => wrapFunctional(c.default || c))
export const HomeContactUs = () => import('../../components/home/ContactUs.vue' /* webpackChunkName: "components/home-contact-us" */).then(c => wrapFunctional(c.default || c))
export const HomeCustomerCard = () => import('../../components/home/CustomerCard.vue' /* webpackChunkName: "components/home-customer-card" */).then(c => wrapFunctional(c.default || c))
export const HomeFaqSection = () => import('../../components/home/FaqSection.vue' /* webpackChunkName: "components/home-faq-section" */).then(c => wrapFunctional(c.default || c))
export const HomeFeatueDetails = () => import('../../components/home/FeatueDetails.vue' /* webpackChunkName: "components/home-featue-details" */).then(c => wrapFunctional(c.default || c))
export const HomeHappyCustomerSection = () => import('../../components/home/HappyCustomerSection.vue' /* webpackChunkName: "components/home-happy-customer-section" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroBlock = () => import('../../components/home/HeroBlock.vue' /* webpackChunkName: "components/home-hero-block" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroContent = () => import('../../components/home/HeroContent.vue' /* webpackChunkName: "components/home-hero-content" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroSection = () => import('../../components/home/HeroSection.vue' /* webpackChunkName: "components/home-hero-section" */).then(c => wrapFunctional(c.default || c))
export const HomeFooter = () => import('../../components/home/HomeFooter.vue' /* webpackChunkName: "components/home-footer" */).then(c => wrapFunctional(c.default || c))
export const HomeNavbar = () => import('../../components/home/HomeNavbar.vue' /* webpackChunkName: "components/home-navbar" */).then(c => wrapFunctional(c.default || c))
export const HomePageTitle = () => import('../../components/home/HomePageTitle.vue' /* webpackChunkName: "components/home-page-title" */).then(c => wrapFunctional(c.default || c))
export const HomeSidebar = () => import('../../components/home/HomeSidebar.vue' /* webpackChunkName: "components/home-sidebar" */).then(c => wrapFunctional(c.default || c))
export const HomeNewsCard = () => import('../../components/home/NewsCard.vue' /* webpackChunkName: "components/home-news-card" */).then(c => wrapFunctional(c.default || c))
export const HomeNewsSection = () => import('../../components/home/NewsSection.vue' /* webpackChunkName: "components/home-news-section" */).then(c => wrapFunctional(c.default || c))
export const HomeNewsletter = () => import('../../components/home/Newsletter.vue' /* webpackChunkName: "components/home-newsletter" */).then(c => wrapFunctional(c.default || c))
export const HomeTrySampleQuestion = () => import('../../components/home/TrySampleQuestion.vue' /* webpackChunkName: "components/home-try-sample-question" */).then(c => wrapFunctional(c.default || c))
export const HomeWhyBraingymjr = () => import('../../components/home/WhyBraingymjr.vue' /* webpackChunkName: "components/home-why-braingymjr" */).then(c => wrapFunctional(c.default || c))
export const JumpTestSet = () => import('../../components/jump-test/JumpTestSet.vue' /* webpackChunkName: "components/jump-test-set" */).then(c => wrapFunctional(c.default || c))
export const JumpTestTimer = () => import('../../components/jump-test/JumpTestTimer.vue' /* webpackChunkName: "components/jump-test-timer" */).then(c => wrapFunctional(c.default || c))
export const JumpTestLevelsEarned = () => import('../../components/jump-test/LevelsEarned.vue' /* webpackChunkName: "components/jump-test-levels-earned" */).then(c => wrapFunctional(c.default || c))
export const LeadeboardReferalLeaderboardReferalDialog = () => import('../../components/leadeboard-referal/leaderboardReferalDialog.vue' /* webpackChunkName: "components/leadeboard-referal-leaderboard-referal-dialog" */).then(c => wrapFunctional(c.default || c))
export const PopupMessage = () => import('../../components/popup-dialog/PopupMessage.vue' /* webpackChunkName: "components/popup-message" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetChooseSubject = () => import('../../components/practice-questionset/ChooseSubject.vue' /* webpackChunkName: "components/practice-questionset-choose-subject" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetLessons = () => import('../../components/practice-questionset/Lessons.vue' /* webpackChunkName: "components/practice-questionset-lessons" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetLevelSelector = () => import('../../components/practice-questionset/LevelSelector.vue' /* webpackChunkName: "components/practice-questionset-level-selector" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetPointsEarned = () => import('../../components/practice-questionset/PointsEarned.vue' /* webpackChunkName: "components/practice-questionset-points-earned" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionSet = () => import('../../components/practice-questionset/PracticeQuestionSet.vue' /* webpackChunkName: "components/practice-question-set" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetSampleQuestionPointsEarned = () => import('../../components/practice-questionset/SampleQuestionPointsEarned.vue' /* webpackChunkName: "components/practice-questionset-sample-question-points-earned" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetSubjectList = () => import('../../components/practice-questionset/SubjectList.vue' /* webpackChunkName: "components/practice-questionset-subject-list" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetSubjectTabs = () => import('../../components/practice-questionset/SubjectTabs.vue' /* webpackChunkName: "components/practice-questionset-subject-tabs" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetTopicSelector = () => import('../../components/practice-questionset/TopicSelector.vue' /* webpackChunkName: "components/practice-questionset-topic-selector" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesAdminQuestionTypeView = () => import('../../components/question_types/AdminQuestionTypeView.vue' /* webpackChunkName: "components/question-types-admin-question-type-view" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesAdminQuestionView = () => import('../../components/question_types/AdminQuestionView.vue' /* webpackChunkName: "components/question-types-admin-question-view" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesBoxPuzzleType = () => import('../../components/question_types/BoxPuzzleType.vue' /* webpackChunkName: "components/question-types-box-puzzle-type" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesBoxType = () => import('../../components/question_types/BoxType.vue' /* webpackChunkName: "components/question-types-box-type" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesCalculatorType = () => import('../../components/question_types/CalculatorType.vue' /* webpackChunkName: "components/question-types-calculator-type" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesClockType = () => import('../../components/question_types/ClockType.vue' /* webpackChunkName: "components/question-types-clock-type" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesCorrectTheSentence = () => import('../../components/question_types/CorrectTheSentence.vue' /* webpackChunkName: "components/question-types-correct-the-sentence" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesCrossword = () => import('../../components/question_types/Crossword.vue' /* webpackChunkName: "components/question-types-crossword" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesDigitType = () => import('../../components/question_types/DigitType.vue' /* webpackChunkName: "components/question-types-digit-type" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesFillInTheBlanks = () => import('../../components/question_types/FillInTheBlanks.vue' /* webpackChunkName: "components/question-types-fill-in-the-blanks" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesGridPattern = () => import('../../components/question_types/GridPattern.vue' /* webpackChunkName: "components/question-types-grid-pattern" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesMatchTheOneToMany = () => import('../../components/question_types/MatchTheOneToMany.vue' /* webpackChunkName: "components/question-types-match-the-one-to-many" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesMatchTheOneToOne = () => import('../../components/question_types/MatchTheOneToOne.vue' /* webpackChunkName: "components/question-types-match-the-one-to-one" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesMultiSelect = () => import('../../components/question_types/MultiSelect.vue' /* webpackChunkName: "components/question-types-multi-select" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesMultipleChoice = () => import('../../components/question_types/MultipleChoice.vue' /* webpackChunkName: "components/question-types-multiple-choice" */).then(c => wrapFunctional(c.default || c))
export const Question = () => import('../../components/question_types/Question.vue' /* webpackChunkName: "components/question" */).then(c => wrapFunctional(c.default || c))
export const QuestionType = () => import('../../components/question_types/QuestionType.vue' /* webpackChunkName: "components/question-type" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesRearrange = () => import('../../components/question_types/Rearrange.vue' /* webpackChunkName: "components/question-types-rearrange" */).then(c => wrapFunctional(c.default || c))
export const QuestionTypesTableType = () => import('../../components/question_types/TableType.vue' /* webpackChunkName: "components/question-types-table-type" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionAddEditSubscription = () => import('../../components/subscription/AddEditSubscription.vue' /* webpackChunkName: "components/subscription-add-edit-subscription" */).then(c => wrapFunctional(c.default || c))
export const TermsConditionContent = () => import('../../components/terms-condition/terms-condition-content.vue' /* webpackChunkName: "components/terms-condition-content" */).then(c => wrapFunctional(c.default || c))
export const TermsConditonDialog = () => import('../../components/terms-condition/termsConditonDialog.vue' /* webpackChunkName: "components/terms-conditon-dialog" */).then(c => wrapFunctional(c.default || c))
export const ChatChatAreaMessage = () => import('../../components/Chat/ChatArea/Message.vue' /* webpackChunkName: "components/chat-chat-area-message" */).then(c => wrapFunctional(c.default || c))
export const ChatChatAreaQuestionCard = () => import('../../components/Chat/ChatArea/QuestionCard.vue' /* webpackChunkName: "components/chat-chat-area-question-card" */).then(c => wrapFunctional(c.default || c))
export const ChatChatAreaTradeCard = () => import('../../components/Chat/ChatArea/TradeCard.vue' /* webpackChunkName: "components/chat-chat-area-trade-card" */).then(c => wrapFunctional(c.default || c))
export const ChatChatArea = () => import('../../components/Chat/ChatArea/index.vue' /* webpackChunkName: "components/chat-chat-area" */).then(c => wrapFunctional(c.default || c))
export const ClockTypeQuestionVueClock = () => import('../../components/ClockTypeQuestion/vue-clock/index.js' /* webpackChunkName: "components/clock-type-question-vue-clock" */).then(c => wrapFunctional(c.default || c))
export const ShopBreadCrumbs = () => import('../../components/Shop/BreadCrumbs/index.vue' /* webpackChunkName: "components/shop-bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const ShopCheckoutAddress = () => import('../../components/Shop/Checkout/Address.vue' /* webpackChunkName: "components/shop-checkout-address" */).then(c => wrapFunctional(c.default || c))
export const ShopCheckoutPayment = () => import('../../components/Shop/Checkout/Payment.vue' /* webpackChunkName: "components/shop-checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const ShopCheckoutCart = () => import('../../components/Shop/Checkout/cart.vue' /* webpackChunkName: "components/shop-checkout-cart" */).then(c => wrapFunctional(c.default || c))
export const ShopMastersBrands = () => import('../../components/Shop/Masters/Brands.vue' /* webpackChunkName: "components/shop-masters-brands" */).then(c => wrapFunctional(c.default || c))
export const ShopMastersCategories = () => import('../../components/Shop/Masters/Categories.vue' /* webpackChunkName: "components/shop-masters-categories" */).then(c => wrapFunctional(c.default || c))
export const ShopOffersDetails = () => import('../../components/Shop/Offers/Details.vue' /* webpackChunkName: "components/shop-offers-details" */).then(c => wrapFunctional(c.default || c))
export const ShopOffersProductSelector = () => import('../../components/Shop/Offers/ProductSelector.vue' /* webpackChunkName: "components/shop-offers-product-selector" */).then(c => wrapFunctional(c.default || c))
export const ShopOffersQuestionSetSelector = () => import('../../components/Shop/Offers/QuestionSetSelector.vue' /* webpackChunkName: "components/shop-offers-question-set-selector" */).then(c => wrapFunctional(c.default || c))
export const ShopOrdersOrderDetails = () => import('../../components/Shop/Orders/OrderDetails.vue' /* webpackChunkName: "components/shop-orders-order-details" */).then(c => wrapFunctional(c.default || c))
export const ShopOrdersOrderList = () => import('../../components/Shop/Orders/OrderList.vue' /* webpackChunkName: "components/shop-orders-order-list" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsDetails = () => import('../../components/Shop/Products/Details.vue' /* webpackChunkName: "components/shop-products-details" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsFilter = () => import('../../components/Shop/Products/Filter.vue' /* webpackChunkName: "components/shop-products-filter" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsProductList = () => import('../../components/Shop/Products/ProductList.vue' /* webpackChunkName: "components/shop-products-product-list" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsManager = () => import('../../components/Shop/Products/ProductsManager.vue' /* webpackChunkName: "components/shop-products-manager" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsUsers = () => import('../../components/Shop/Products/Users.vue' /* webpackChunkName: "components/shop-products-users" */).then(c => wrapFunctional(c.default || c))
export const StoriesSummaryIconsAccuracy = () => import('../../components/Stories/SummaryIcons/Accuracy.vue' /* webpackChunkName: "components/stories-summary-icons-accuracy" */).then(c => wrapFunctional(c.default || c))
export const StoriesSummaryIconsPoints = () => import('../../components/Stories/SummaryIcons/Points.vue' /* webpackChunkName: "components/stories-summary-icons-points" */).then(c => wrapFunctional(c.default || c))
export const StoriesSummaryIconsTime = () => import('../../components/Stories/SummaryIcons/Time.vue' /* webpackChunkName: "components/stories-summary-icons-time" */).then(c => wrapFunctional(c.default || c))
export const ChildProfileAchievementsBadgesAndCertificates = () => import('../../components/childProfile/Achievements/BadgesAndCertificates.vue' /* webpackChunkName: "components/child-profile-achievements-badges-and-certificates" */).then(c => wrapFunctional(c.default || c))
export const ChildProfileAchievementsSelectedAchievement = () => import('../../components/childProfile/Achievements/SelectedAchievement.vue' /* webpackChunkName: "components/child-profile-achievements-selected-achievement" */).then(c => wrapFunctional(c.default || c))
export const ChildProfileAchievements = () => import('../../components/childProfile/Achievements/index.vue' /* webpackChunkName: "components/child-profile-achievements" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsOpportunityBoxAttemptJourney = () => import('../../components/daily-questions/OpportunityBox/AttemptJourney.vue' /* webpackChunkName: "components/daily-questions-opportunity-box-attempt-journey" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsOpportunityBoxAttemptStatus = () => import('../../components/daily-questions/OpportunityBox/AttemptStatus.vue' /* webpackChunkName: "components/daily-questions-opportunity-box-attempt-status" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsOpportunityBoxOpportunitiesDialog = () => import('../../components/daily-questions/OpportunityBox/OpportunitiesDialog.vue' /* webpackChunkName: "components/daily-questions-opportunity-box-opportunities-dialog" */).then(c => wrapFunctional(c.default || c))
export const DailyQuestionsOpportunityBoxOpportunitiesView = () => import('../../components/daily-questions/OpportunityBox/OpportunitiesView.vue' /* webpackChunkName: "components/daily-questions-opportunity-box-opportunities-view" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesCompetition = () => import('../../components/practice-questionset/Games/Competition.vue' /* webpackChunkName: "components/practice-questionset-games-competition" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesDeclined = () => import('../../components/practice-questionset/Games/Declined.vue' /* webpackChunkName: "components/practice-questionset-games-declined" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesGameStartCountDown = () => import('../../components/practice-questionset/Games/GameStartCountDown.vue' /* webpackChunkName: "components/practice-questionset-games-game-start-count-down" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesNotResponded = () => import('../../components/practice-questionset/Games/NotResponded.vue' /* webpackChunkName: "components/practice-questionset-games-not-responded" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesPlayersInfo = () => import('../../components/practice-questionset/Games/PlayersInfo.vue' /* webpackChunkName: "components/practice-questionset-games-players-info" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesSummary = () => import('../../components/practice-questionset/Games/Summary.vue' /* webpackChunkName: "components/practice-questionset-games-summary" */).then(c => wrapFunctional(c.default || c))
export const PracticeQuestionsetGamesWaiting = () => import('../../components/practice-questionset/Games/Waiting.vue' /* webpackChunkName: "components/practice-questionset-games-waiting" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariationsImages = () => import('../../components/Shop/Products/Variations/Images.vue' /* webpackChunkName: "components/shop-products-variations-images" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariationsProductForm = () => import('../../components/Shop/Products/Variations/ProductForm.vue' /* webpackChunkName: "components/shop-products-variations-product-form" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariationsEmailTemplate = () => import('../../components/Shop/Products/Variations/emailTemplate.vue' /* webpackChunkName: "components/shop-products-variations-email-template" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariations = () => import('../../components/Shop/Products/Variations/index.vue' /* webpackChunkName: "components/shop-products-variations" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
