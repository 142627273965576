export const COUPON_ERROR_MAP = {
  CLAIMED: 'CLAIMED',
  BULK_USED: 'BULK_USED',
  INVALID_MODULE: 'INVALID_MODULE',
  UNAVAILABLE: 'UNAVAILABLE',
  INVALID_PLATFORM: 'INVALID_PLATFORM',
  EXPIRED: 'EXPIRED',
  INVALID_COUNTRY: 'INVALID_COUNTRY',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_COUPON: 'INVALID_COUPON',
  INSUFFICIENT_CART_VALUE: 'INSUFFICIENT_CART_VALUE'
};

export const COUPON_ERROR_MESSAGES = {
  [COUPON_ERROR_MAP.CLAIMED]: data => `Invalid coupon`,
  [COUPON_ERROR_MAP.BULK_USED]: data => `Invalid coupon`,
  [COUPON_ERROR_MAP.INVALID_MODULE]: data => `Invalid coupon`,
  [COUPON_ERROR_MAP.UNAVAILABLE]: data => `Invalid coupon`,
  [COUPON_ERROR_MAP.INVALID_PLATFORM]: data => `Invalid coupon`,
  [COUPON_ERROR_MAP.EXPIRED]: data => `Invalid coupon`,
  [COUPON_ERROR_MAP.INVALID_COUNTRY]: data =>
    `Coupon not available in your country`,
  [COUPON_ERROR_MAP.INVALID_EMAIL]: data => `Coupon invalid`,
  [COUPON_ERROR_MAP.INVALID_PHONE]: data => `Coupon invalid'`,
  [COUPON_ERROR_MAP.INVALID_COUPON]: data => `Coupon invalid'`,
  [COUPON_ERROR_MAP.INSUFFICIENT_CART_VALUE]: data =>
    `Cart value should be more than ${data.minCartValue} to apply this coupon`
};

export const STORY_CONSTANTS = {
  PLAY_METHOD_MESSAGE: 'Message',
  PLAY_METHOD_SOUND: 'Sound',
  PLAY_METHOD_MESSAGE_AND_SOUND: 'Message and Sound',
  PAGE_STORY: 'PAGE_STORY',
  PAGE_SUMMARY: 'PAGE_SUMMARY'
};

export const SUBJECTS = {
  REAL_WORLD: 1,
  MATH: 2,
  ENGLISH: 3,
  STORY: 4
};

export const SUBJECT_NAME = {
  1: 'Real World',
  2: 'Maths',
  3: 'English'
};

export const SHOP = {
  PRODUCT_TYPE: {
    PARTNER_BRAND: 'Partner Brand',
    SUBSCRIPTION: 'Subscription',
    THIRD_PARTY_COUPONS: 'Third Party Coupons',
    THIRD_PARTY_COUPON_WITH_SUBSCRIPTION: 'Third Party Coupon with Subscription'
  }
};

export const videoFileTypes = [
  'video/mp4',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv'
];
export const imageFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif'
];
export const documentFileType = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain'
];
export const acceptedFileTypes = [
  ...videoFileTypes,
  ...imageFileTypes,
  ...documentFileType
];
