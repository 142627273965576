import backend from "@/lib/backend";

const SERVICE_NAME = "challenges";

export default axios => ({
  get(controller, options) {
    options.controller = controller;

    return backend(axios, SERVICE_NAME).find(options);
  }
});
