
import { mdiExitToApp, mdiTicketPercentOutline } from '@mdi/js';
export default {
  middleware: ['auth', 'shop-user-only', 'under-maintenance'],
  data: () => ({
    icons: {
      mdiExitToApp
    },
    dialog: false,
    closable: true,
    selectedItem: 0,
    items: [
      {
        text: 'Products',
        icon: mdiTicketPercentOutline,
        url: '/shop-users/products'
      },
      {
        text: 'Orders',
        icon: mdiTicketPercentOutline,
        url: '/shop-users/orders'
      }
    ],
    drawer: true
  }),
  mounted() {},
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.replace('/');
    }
  }
};
