import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _412efd9b = () => interopDefault(import('../pages/aboutUs.vue' /* webpackChunkName: "pages/aboutUs" */))
const _2d0cadf6 = () => interopDefault(import('../pages/aboutUsPaytm.vue' /* webpackChunkName: "pages/aboutUsPaytm" */))
const _112741a0 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _2d92f5ff = () => interopDefault(import('../pages/ccsupport.vue' /* webpackChunkName: "pages/ccsupport" */))
const _e6f8efb0 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _0493b1c9 = () => interopDefault(import('../pages/claim-your-gift.vue' /* webpackChunkName: "pages/claim-your-gift" */))
const _41bd30d6 = () => interopDefault(import('../pages/class-2-worksheets.vue' /* webpackChunkName: "pages/class-2-worksheets" */))
const _2af76fea = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _3bdffea4 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _02e02faa = () => interopDefault(import('../pages/email-template.vue' /* webpackChunkName: "pages/email-template" */))
const _065dbf61 = () => interopDefault(import('../pages/english-worksheet.vue' /* webpackChunkName: "pages/english-worksheet" */))
const _172aefb4 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _5950e444 = () => interopDefault(import('../pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _0801ff29 = () => interopDefault(import('../pages/free-signup.vue' /* webpackChunkName: "pages/free-signup" */))
const _0d2e1bed = () => interopDefault(import('../pages/giftsubscription.vue' /* webpackChunkName: "pages/giftsubscription" */))
const _638e7227 = () => interopDefault(import('../pages/history/index.vue' /* webpackChunkName: "pages/history/index" */))
const _0219e60e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _741a0e09 = () => interopDefault(import('../pages/math-worksheet.vue' /* webpackChunkName: "pages/math-worksheet" */))
const _5d906b16 = () => interopDefault(import('../pages/myplan.vue' /* webpackChunkName: "pages/myplan" */))
const _c70b5422 = () => interopDefault(import('../pages/payNearBySignup.vue' /* webpackChunkName: "pages/payNearBySignup" */))
const _4a426ba8 = () => interopDefault(import('../pages/payNearBySignUpMessage.vue' /* webpackChunkName: "pages/payNearBySignUpMessage" */))
const _2b1de6f6 = () => interopDefault(import('../pages/paytmlanding.vue' /* webpackChunkName: "pages/paytmlanding" */))
const _37ae62e9 = () => interopDefault(import('../pages/paytmsubscribe.vue' /* webpackChunkName: "pages/paytmsubscribe" */))
const _00226ab6 = () => interopDefault(import('../pages/press-releases.vue' /* webpackChunkName: "pages/press-releases" */))
const _4f3617ca = () => interopDefault(import('../pages/privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _417aca73 = () => interopDefault(import('../pages/profileImageUpload.vue' /* webpackChunkName: "pages/profileImageUpload" */))
const _5e32fd3d = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _1bb895fa = () => interopDefault(import('../pages/resetpassword.vue' /* webpackChunkName: "pages/resetpassword" */))
const _3cf2d182 = () => interopDefault(import('../pages/returnpolicy.vue' /* webpackChunkName: "pages/returnpolicy" */))
const _6554abce = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _060f3162 = () => interopDefault(import('../pages/signon-new.vue' /* webpackChunkName: "pages/signon-new" */))
const _418c27ea = () => interopDefault(import('../pages/signup-new.vue' /* webpackChunkName: "pages/signup-new" */))
const _9ba5ac9a = () => interopDefault(import('../pages/signup-partner.vue' /* webpackChunkName: "pages/signup-partner" */))
const _ee1d65b2 = () => interopDefault(import('../pages/signup-with-samples.vue' /* webpackChunkName: "pages/signup-with-samples" */))
const _63ce85e6 = () => interopDefault(import('../pages/ssr-test.vue' /* webpackChunkName: "pages/ssr-test" */))
const _0228df95 = () => interopDefault(import('../pages/staugustinesday.vue' /* webpackChunkName: "pages/staugustinesday" */))
const _76412e3b = () => interopDefault(import('../pages/students/index.vue' /* webpackChunkName: "pages/students/index" */))
const _797eb34c = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _7a18c4bb = () => interopDefault(import('../pages/termsandcondition.vue' /* webpackChunkName: "pages/termsandcondition" */))
const _21f347b2 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _63143a30 = () => interopDefault(import('../pages/token-login/index.vue' /* webpackChunkName: "pages/token-login/index" */))
const _13799b09 = () => interopDefault(import('../pages/trial-ads.vue' /* webpackChunkName: "pages/trial-ads" */))
const _2a5f4db2 = () => interopDefault(import('../pages/try-sample-question.vue' /* webpackChunkName: "pages/try-sample-question" */))
const _49ee4aae = () => interopDefault(import('../pages/under-maintenance.vue' /* webpackChunkName: "pages/under-maintenance" */))
const _f8861f38 = () => interopDefault(import('../pages/varify-email.vue' /* webpackChunkName: "pages/varify-email" */))
const _b77aee90 = () => interopDefault(import('../pages/admin/active-child/index.vue' /* webpackChunkName: "pages/admin/active-child/index" */))
const _828df448 = () => interopDefault(import('../pages/admin/additional-points/index.vue' /* webpackChunkName: "pages/admin/additional-points/index" */))
const _3d6f0031 = () => interopDefault(import('../pages/admin/archive-points/index.vue' /* webpackChunkName: "pages/admin/archive-points/index" */))
const _ad84972c = () => interopDefault(import('../pages/admin/child-accuracy.vue' /* webpackChunkName: "pages/admin/child-accuracy" */))
const _5641ab7e = () => interopDefault(import('../pages/admin/child-active-history.vue' /* webpackChunkName: "pages/admin/child-active-history" */))
const _ceab83d4 = () => interopDefault(import('../pages/admin/child-competition-details/index.vue' /* webpackChunkName: "pages/admin/child-competition-details/index" */))
const _80012358 = () => interopDefault(import('../pages/admin/child-monthly-points.vue' /* webpackChunkName: "pages/admin/child-monthly-points" */))
const _0b90b44a = () => interopDefault(import('../pages/admin/child-points-history.vue' /* webpackChunkName: "pages/admin/child-points-history" */))
const _653dc46d = () => interopDefault(import('../pages/admin/competition-winner.vue' /* webpackChunkName: "pages/admin/competition-winner" */))
const _e17ca2c0 = () => interopDefault(import('../pages/admin/coupons/index.vue' /* webpackChunkName: "pages/admin/coupons/index" */))
const _3672fd32 = () => interopDefault(import('../pages/admin/create-question.vue' /* webpackChunkName: "pages/admin/create-question" */))
const _1052207c = () => interopDefault(import('../pages/admin/create-question-set.vue' /* webpackChunkName: "pages/admin/create-question-set" */))
const _6dcb04b7 = () => interopDefault(import('../pages/admin/custom-ads/index.vue' /* webpackChunkName: "pages/admin/custom-ads/index" */))
const _4fb76f46 = () => interopDefault(import('../pages/admin/daily-question-performance.vue' /* webpackChunkName: "pages/admin/daily-question-performance" */))
const _6e09a919 = () => interopDefault(import('../pages/admin/edit-question.vue' /* webpackChunkName: "pages/admin/edit-question" */))
const _68ee863a = () => interopDefault(import('../pages/admin/encryption.vue' /* webpackChunkName: "pages/admin/encryption" */))
const _4f46284d = () => interopDefault(import('../pages/admin/leaderboard.vue' /* webpackChunkName: "pages/admin/leaderboard" */))
const _5cd0ba6e = () => interopDefault(import('../pages/admin/leaderboard-referal/index.vue' /* webpackChunkName: "pages/admin/leaderboard-referal/index" */))
const _f12b4b64 = () => interopDefault(import('../pages/admin/lessons/index.vue' /* webpackChunkName: "pages/admin/lessons/index" */))
const _30bc4e28 = () => interopDefault(import('../pages/admin/question-performance/index.vue' /* webpackChunkName: "pages/admin/question-performance/index" */))
const _19503abc = () => interopDefault(import('../pages/admin/question-set-history.vue' /* webpackChunkName: "pages/admin/question-set-history" */))
const _5fc98eac = () => interopDefault(import('../pages/admin/question-set-of-the-day.vue' /* webpackChunkName: "pages/admin/question-set-of-the-day" */))
const _77ef2fc6 = () => interopDefault(import('../pages/admin/questions.vue' /* webpackChunkName: "pages/admin/questions" */))
const _28006292 = () => interopDefault(import('../pages/admin/questionSets.vue' /* webpackChunkName: "pages/admin/questionSets" */))
const _66e91b4c = () => interopDefault(import('../pages/admin/shop-order/index.vue' /* webpackChunkName: "pages/admin/shop-order/index" */))
const _6401fb34 = () => interopDefault(import('../pages/admin/stories/index.vue' /* webpackChunkName: "pages/admin/stories/index" */))
const _2972d349 = () => interopDefault(import('../pages/admin/subscriptions/index.vue' /* webpackChunkName: "pages/admin/subscriptions/index" */))
const _a331f09c = () => interopDefault(import('../pages/admin/undermaintance.vue' /* webpackChunkName: "pages/admin/undermaintance" */))
const _5cc59fdb = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _19361174 = () => interopDefault(import('../pages/admin/wild-card/index.vue' /* webpackChunkName: "pages/admin/wild-card/index" */))
const _b77e7380 = () => interopDefault(import('../pages/advertisers/dashboard/index.vue' /* webpackChunkName: "pages/advertisers/dashboard/index" */))
const _6655990e = () => interopDefault(import('../pages/affiliate/sign-up.vue' /* webpackChunkName: "pages/affiliate/sign-up" */))
const _77b74fd4 = () => interopDefault(import('../pages/affiliate/terms-and-conditions.vue' /* webpackChunkName: "pages/affiliate/terms-and-conditions" */))
const _ea54b982 = () => interopDefault(import('../pages/coupon-users/coupons.vue' /* webpackChunkName: "pages/coupon-users/coupons" */))
const _6480dec7 = () => interopDefault(import('../pages/history/HistoryList.vue' /* webpackChunkName: "pages/history/HistoryList" */))
const _1f920cec = () => interopDefault(import('../pages/parents/achievements.vue' /* webpackChunkName: "pages/parents/achievements" */))
const _3ce7f5a8 = () => interopDefault(import('../pages/parents/assessment.vue' /* webpackChunkName: "pages/parents/assessment" */))
const _0057b659 = () => interopDefault(import('../pages/parents/challenges/index.vue' /* webpackChunkName: "pages/parents/challenges/index" */))
const _d63d4270 = () => interopDefault(import('../pages/parents/chat-history/index.vue' /* webpackChunkName: "pages/parents/chat-history/index" */))
const _39e6b670 = () => interopDefault(import('../pages/parents/chatTest.vue' /* webpackChunkName: "pages/parents/chatTest" */))
const _59e79cf6 = () => interopDefault(import('../pages/parents/child-profile/index.vue' /* webpackChunkName: "pages/parents/child-profile/index" */))
const _7050de14 = () => interopDefault(import('../pages/parents/claimgift.vue' /* webpackChunkName: "pages/parents/claimgift" */))
const _53aa53f1 = () => interopDefault(import('../pages/parents/create-child.vue' /* webpackChunkName: "pages/parents/create-child" */))
const _02899823 = () => interopDefault(import('../pages/parents/daily-questions.vue' /* webpackChunkName: "pages/parents/daily-questions" */))
const _13c69379 = () => interopDefault(import('../pages/parents/digital-store/index.vue' /* webpackChunkName: "pages/parents/digital-store/index" */))
const _47532f1a = () => interopDefault(import('../pages/parents/friend-request-approval.vue' /* webpackChunkName: "pages/parents/friend-request-approval" */))
const _0da61856 = () => interopDefault(import('../pages/parents/jump-request.vue' /* webpackChunkName: "pages/parents/jump-request" */))
const _9960bc4a = () => interopDefault(import('../pages/parents/jump-test.vue' /* webpackChunkName: "pages/parents/jump-test" */))
const _2a4abe53 = () => interopDefault(import('../pages/parents/kids.vue' /* webpackChunkName: "pages/parents/kids" */))
const _076efff2 = () => interopDefault(import('../pages/parents/leaderboard.vue' /* webpackChunkName: "pages/parents/leaderboard" */))
const _1173a47f = () => interopDefault(import('../pages/parents/mykids.vue' /* webpackChunkName: "pages/parents/mykids" */))
const _5a3cdd28 = () => interopDefault(import('../pages/parents/parent-profile.vue' /* webpackChunkName: "pages/parents/parent-profile" */))
const _f06b7e38 = () => interopDefault(import('../pages/parents/practice/index.vue' /* webpackChunkName: "pages/parents/practice/index" */))
const _fbe7c15a = () => interopDefault(import('../pages/parents/pricing/index.vue' /* webpackChunkName: "pages/parents/pricing/index" */))
const _4dc17c16 = () => interopDefault(import('../pages/parents/social/index.vue' /* webpackChunkName: "pages/parents/social/index" */))
const _43ca53a0 = () => interopDefault(import('../pages/parents/stories/index.vue' /* webpackChunkName: "pages/parents/stories/index" */))
const _53b25703 = () => interopDefault(import('../pages/parents/subscription.vue' /* webpackChunkName: "pages/parents/subscription" */))
const _17432df8 = () => interopDefault(import('../pages/parents/test.vue' /* webpackChunkName: "pages/parents/test" */))
const _1d17ff74 = () => interopDefault(import('../pages/parents/thank-you.vue' /* webpackChunkName: "pages/parents/thank-you" */))
const _5fb42f16 = () => interopDefault(import('../pages/shop-users/orders/index.vue' /* webpackChunkName: "pages/shop-users/orders/index" */))
const _6c5ecc52 = () => interopDefault(import('../pages/shop-users/products.vue' /* webpackChunkName: "pages/shop-users/products" */))
const _1148f950 = () => interopDefault(import('../pages/shop/brands/index.vue' /* webpackChunkName: "pages/shop/brands/index" */))
const _7d405cb5 = () => interopDefault(import('../pages/shop/Brands.vue' /* webpackChunkName: "pages/shop/Brands" */))
const _06c3e7ec = () => interopDefault(import('../pages/shop/cart/index.vue' /* webpackChunkName: "pages/shop/cart/index" */))
const _6c5d41b0 = () => interopDefault(import('../pages/shop/categories/index.vue' /* webpackChunkName: "pages/shop/categories/index" */))
const _9b1f76f6 = () => interopDefault(import('../pages/shop/Categories.vue' /* webpackChunkName: "pages/shop/Categories" */))
const _73c48212 = () => interopDefault(import('../pages/shop/checkout/index.vue' /* webpackChunkName: "pages/shop/checkout/index" */))
const _257fd02e = () => interopDefault(import('../pages/shop/Deals.vue' /* webpackChunkName: "pages/shop/Deals" */))
const _b028e53a = () => interopDefault(import('../pages/shop/offers/index.vue' /* webpackChunkName: "pages/shop/offers/index" */))
const _34f78b00 = () => interopDefault(import('../pages/shop/Offers.vue' /* webpackChunkName: "pages/shop/Offers" */))
const _37ed5b5f = () => interopDefault(import('../pages/shop/order-history/index.vue' /* webpackChunkName: "pages/shop/order-history/index" */))
const _71660ea0 = () => interopDefault(import('../pages/shop/products/index.vue' /* webpackChunkName: "pages/shop/products/index" */))
const _401c0062 = () => interopDefault(import('../pages/admin/additional-points/create.vue' /* webpackChunkName: "pages/admin/additional-points/create" */))
const _05047b0d = () => interopDefault(import('../pages/admin/advertisements/ad-specification/index.vue' /* webpackChunkName: "pages/admin/advertisements/ad-specification/index" */))
const _356f5fca = () => interopDefault(import('../pages/admin/advertisements/advertisers/index.vue' /* webpackChunkName: "pages/admin/advertisements/advertisers/index" */))
const _35cee8fe = () => interopDefault(import('../pages/admin/advertisements/formats/index.vue' /* webpackChunkName: "pages/admin/advertisements/formats/index" */))
const _7f693311 = () => interopDefault(import('../pages/admin/advertisements/units/index.vue' /* webpackChunkName: "pages/admin/advertisements/units/index" */))
const _c5ce041a = () => interopDefault(import('../pages/admin/coupons/affiliate-inquiries.vue' /* webpackChunkName: "pages/admin/coupons/affiliate-inquiries" */))
const _0ecdb448 = () => interopDefault(import('../pages/admin/coupons/coupon-usage.vue' /* webpackChunkName: "pages/admin/coupons/coupon-usage" */))
const _63ef85f2 = () => interopDefault(import('../pages/admin/digital-store/trades.vue' /* webpackChunkName: "pages/admin/digital-store/trades" */))
const _50c03d18 = () => interopDefault(import('../pages/admin/shop/masters.vue' /* webpackChunkName: "pages/admin/shop/masters" */))
const _004b36ba = () => interopDefault(import('../pages/admin/shop/offers/index.vue' /* webpackChunkName: "pages/admin/shop/offers/index" */))
const _7267cc71 = () => interopDefault(import('../pages/admin/shop/orders/index.vue' /* webpackChunkName: "pages/admin/shop/orders/index" */))
const _423a0020 = () => interopDefault(import('../pages/admin/shop/products/index.vue' /* webpackChunkName: "pages/admin/shop/products/index" */))
const _2c9be095 = () => interopDefault(import('../pages/admin/subscriptions/create.vue' /* webpackChunkName: "pages/admin/subscriptions/create" */))
const _3fbd35aa = () => interopDefault(import('../pages/admin/subscriptions/track-subscriptions.vue' /* webpackChunkName: "pages/admin/subscriptions/track-subscriptions" */))
const _91efc224 = () => interopDefault(import('../pages/parents/challenges/trending.vue' /* webpackChunkName: "pages/parents/challenges/trending" */))
const _7e5c1b1e = () => interopDefault(import('../pages/parents/challenges/winners/index.vue' /* webpackChunkName: "pages/parents/challenges/winners/index" */))
const _4409d921 = () => interopDefault(import('../pages/parents/digital-store/avatars.vue' /* webpackChunkName: "pages/parents/digital-store/avatars" */))
const _a03d9170 = () => interopDefault(import('../pages/parents/digital-store/cars.vue' /* webpackChunkName: "pages/parents/digital-store/cars" */))
const _d67fae02 = () => interopDefault(import('../pages/parents/digital-store/collections/index.vue' /* webpackChunkName: "pages/parents/digital-store/collections/index" */))
const _1bd94c7e = () => interopDefault(import('../pages/parents/digital-store/icons.vue' /* webpackChunkName: "pages/parents/digital-store/icons" */))
const _3cf6b074 = () => interopDefault(import('../pages/parents/digital-store/inventory/index.vue' /* webpackChunkName: "pages/parents/digital-store/inventory/index" */))
const _7a159a3b = () => interopDefault(import('../pages/parents/digital-store/limited-deals.vue' /* webpackChunkName: "pages/parents/digital-store/limited-deals" */))
const _3489227b = () => interopDefault(import('../pages/parents/digital-store/mystry-box.vue' /* webpackChunkName: "pages/parents/digital-store/mystry-box" */))
const _44f12050 = () => interopDefault(import('../pages/parents/digital-store/new-arrivals.vue' /* webpackChunkName: "pages/parents/digital-store/new-arrivals" */))
const _6944cbce = () => interopDefault(import('../pages/parents/digital-store/orders.vue' /* webpackChunkName: "pages/parents/digital-store/orders" */))
const _6559c646 = () => interopDefault(import('../pages/parents/digital-store/pets.vue' /* webpackChunkName: "pages/parents/digital-store/pets" */))
const _22fc5ee8 = () => interopDefault(import('../pages/parents/digital-store/trade-offers/index.vue' /* webpackChunkName: "pages/parents/digital-store/trade-offers/index" */))
const _3f35968a = () => interopDefault(import('../pages/parents/practice/opponent.vue' /* webpackChunkName: "pages/parents/practice/opponent" */))
const _7601b43d = () => interopDefault(import('../pages/parents/practice/subjectSelector.vue' /* webpackChunkName: "pages/parents/practice/subjectSelector" */))
const _44852281 = () => interopDefault(import('../pages/parents/stories/storyList.vue' /* webpackChunkName: "pages/parents/stories/storyList" */))
const _d8e18150 = () => interopDefault(import('../pages/shop/order-history/track.vue' /* webpackChunkName: "pages/shop/order-history/track" */))
const _2048f406 = () => interopDefault(import('../pages/parents/digital-store/inventory/Profile.vue' /* webpackChunkName: "pages/parents/digital-store/inventory/Profile" */))
const _354b69e2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48584d88 = () => interopDefault(import('../pages/parents/digital-store/trade-offers/history/_id/index.vue' /* webpackChunkName: "pages/parents/digital-store/trade-offers/history/_id/index" */))
const _27f2669c = () => interopDefault(import('../pages/admin/advertisements/advertisers/_id.vue' /* webpackChunkName: "pages/admin/advertisements/advertisers/_id" */))
const _a9ca748e = () => interopDefault(import('../pages/admin/advertisements/units/_id.vue' /* webpackChunkName: "pages/admin/advertisements/units/_id" */))
const _f84b13e4 = () => interopDefault(import('../pages/admin/shop/offers/_id/index.vue' /* webpackChunkName: "pages/admin/shop/offers/_id/index" */))
const _77c8eb7c = () => interopDefault(import('../pages/admin/shop/orders/_item_id/index.vue' /* webpackChunkName: "pages/admin/shop/orders/_item_id/index" */))
const _2b5aa2db = () => interopDefault(import('../pages/admin/shop/products/_id/index.vue' /* webpackChunkName: "pages/admin/shop/products/_id/index" */))
const _79d24d06 = () => interopDefault(import('../pages/parents/challenges/winners/_id.vue' /* webpackChunkName: "pages/parents/challenges/winners/_id" */))
const _aef8ba10 = () => interopDefault(import('../pages/parents/child-profile/certificate/_id/index.vue' /* webpackChunkName: "pages/parents/child-profile/certificate/_id/index" */))
const _2562a72c = () => interopDefault(import('../pages/parents/digital-store/collections/_id/index.vue' /* webpackChunkName: "pages/parents/digital-store/collections/_id/index" */))
const _90b07512 = () => interopDefault(import('../pages/parents/digital-store/trade-offers/_id/index.vue' /* webpackChunkName: "pages/parents/digital-store/trade-offers/_id/index" */))
const _f8b85a72 = () => interopDefault(import('../pages/admin/additional-points/_id/index.vue' /* webpackChunkName: "pages/admin/additional-points/_id/index" */))
const _1a62798b = () => interopDefault(import('../pages/admin/coupons/_id/index.vue' /* webpackChunkName: "pages/admin/coupons/_id/index" */))
const _36f0c8df = () => interopDefault(import('../pages/admin/custom-ads/_id.vue' /* webpackChunkName: "pages/admin/custom-ads/_id" */))
const _2272cf94 = () => interopDefault(import('../pages/admin/lessons/_id.vue' /* webpackChunkName: "pages/admin/lessons/_id" */))
const _4f247a51 = () => interopDefault(import('../pages/admin/stories/_id/index.vue' /* webpackChunkName: "pages/admin/stories/_id/index" */))
const _94755574 = () => interopDefault(import('../pages/admin/users/_id/index.vue' /* webpackChunkName: "pages/admin/users/_id/index" */))
const _9676019e = () => interopDefault(import('../pages/admin/wild-card/_id/index.vue' /* webpackChunkName: "pages/admin/wild-card/_id/index" */))
const _02e17b01 = () => interopDefault(import('../pages/parents/challenges/_id.vue' /* webpackChunkName: "pages/parents/challenges/_id" */))
const _a8670dac = () => interopDefault(import('../pages/parents/edit-child/_id.vue' /* webpackChunkName: "pages/parents/edit-child/_id" */))
const _4bd05a20 = () => interopDefault(import('../pages/parents/payment-detials/_id.vue' /* webpackChunkName: "pages/parents/payment-detials/_id" */))
const _7718be08 = () => interopDefault(import('../pages/parents/stories/_id.vue' /* webpackChunkName: "pages/parents/stories/_id" */))
const _50a0b277 = () => interopDefault(import('../pages/shop-users/orders/_item_id/index.vue' /* webpackChunkName: "pages/shop-users/orders/_item_id/index" */))
const _f8d99b7a = () => interopDefault(import('../pages/shop/brands/_id/index.vue' /* webpackChunkName: "pages/shop/brands/_id/index" */))
const _2d16a613 = () => interopDefault(import('../pages/shop/categories/_id/index.vue' /* webpackChunkName: "pages/shop/categories/_id/index" */))
const _0cad8264 = () => interopDefault(import('../pages/shop/offers/_id/index.vue' /* webpackChunkName: "pages/shop/offers/_id/index" */))
const _689a3b9b = () => interopDefault(import('../pages/shop/products/_id/index.vue' /* webpackChunkName: "pages/shop/products/_id/index" */))
const _4a07fb18 = () => interopDefault(import('../pages/admin/subscriptions/_id/edit.vue' /* webpackChunkName: "pages/admin/subscriptions/_id/edit" */))
const _e0b199ba = () => interopDefault(import('../pages/invite/_referralCode.vue' /* webpackChunkName: "pages/invite/_referralCode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutUs",
    component: _412efd9b,
    name: "aboutUs"
  }, {
    path: "/aboutUsPaytm",
    component: _2d0cadf6,
    name: "aboutUsPaytm"
  }, {
    path: "/affiliate",
    component: _112741a0,
    name: "affiliate"
  }, {
    path: "/ccsupport",
    component: _2d92f5ff,
    name: "ccsupport"
  }, {
    path: "/change-password",
    component: _e6f8efb0,
    name: "change-password"
  }, {
    path: "/claim-your-gift",
    component: _0493b1c9,
    name: "claim-your-gift"
  }, {
    path: "/class-2-worksheets",
    component: _41bd30d6,
    name: "class-2-worksheets"
  }, {
    path: "/contact-us",
    component: _2af76fea,
    name: "contact-us"
  }, {
    path: "/dashboard",
    component: _3bdffea4,
    name: "dashboard"
  }, {
    path: "/email-template",
    component: _02e02faa,
    name: "email-template"
  }, {
    path: "/english-worksheet",
    component: _065dbf61,
    name: "english-worksheet"
  }, {
    path: "/faq",
    component: _172aefb4,
    name: "faq"
  }, {
    path: "/forgotpassword",
    component: _5950e444,
    name: "forgotpassword"
  }, {
    path: "/free-signup",
    component: _0801ff29,
    name: "free-signup"
  }, {
    path: "/giftsubscription",
    component: _0d2e1bed,
    name: "giftsubscription"
  }, {
    path: "/history",
    component: _638e7227,
    name: "history"
  }, {
    path: "/login",
    component: _0219e60e,
    name: "login"
  }, {
    path: "/math-worksheet",
    component: _741a0e09,
    name: "math-worksheet"
  }, {
    path: "/myplan",
    component: _5d906b16,
    name: "myplan"
  }, {
    path: "/payNearBySignup",
    component: _c70b5422,
    name: "payNearBySignup"
  }, {
    path: "/payNearBySignUpMessage",
    component: _4a426ba8,
    name: "payNearBySignUpMessage"
  }, {
    path: "/paytmlanding",
    component: _2b1de6f6,
    name: "paytmlanding"
  }, {
    path: "/paytmsubscribe",
    component: _37ae62e9,
    name: "paytmsubscribe"
  }, {
    path: "/press-releases",
    component: _00226ab6,
    name: "press-releases"
  }, {
    path: "/privacypolicy",
    component: _4f3617ca,
    name: "privacypolicy"
  }, {
    path: "/profileImageUpload",
    component: _417aca73,
    name: "profileImageUpload"
  }, {
    path: "/referral",
    component: _5e32fd3d,
    name: "referral"
  }, {
    path: "/resetpassword",
    component: _1bb895fa,
    name: "resetpassword"
  }, {
    path: "/returnpolicy",
    component: _3cf2d182,
    name: "returnpolicy"
  }, {
    path: "/shop",
    component: _6554abce,
    name: "shop"
  }, {
    path: "/signon-new",
    component: _060f3162,
    name: "signon-new"
  }, {
    path: "/signup-new",
    component: _418c27ea,
    name: "signup-new"
  }, {
    path: "/signup-partner",
    component: _9ba5ac9a,
    name: "signup-partner"
  }, {
    path: "/signup-with-samples",
    component: _ee1d65b2,
    name: "signup-with-samples"
  }, {
    path: "/ssr-test",
    component: _63ce85e6,
    name: "ssr-test"
  }, {
    path: "/staugustinesday",
    component: _0228df95,
    name: "staugustinesday"
  }, {
    path: "/students",
    component: _76412e3b,
    name: "students"
  }, {
    path: "/subscribe",
    component: _797eb34c,
    name: "subscribe"
  }, {
    path: "/termsandcondition",
    component: _7a18c4bb,
    name: "termsandcondition"
  }, {
    path: "/test",
    component: _21f347b2,
    name: "test"
  }, {
    path: "/token-login",
    component: _63143a30,
    name: "token-login"
  }, {
    path: "/trial-ads",
    component: _13799b09,
    name: "trial-ads"
  }, {
    path: "/try-sample-question",
    component: _2a5f4db2,
    name: "try-sample-question"
  }, {
    path: "/under-maintenance",
    component: _49ee4aae,
    name: "under-maintenance"
  }, {
    path: "/varify-email",
    component: _f8861f38,
    name: "varify-email"
  }, {
    path: "/admin/active-child",
    component: _b77aee90,
    name: "admin-active-child"
  }, {
    path: "/admin/additional-points",
    component: _828df448,
    name: "admin-additional-points"
  }, {
    path: "/admin/archive-points",
    component: _3d6f0031,
    name: "admin-archive-points"
  }, {
    path: "/admin/child-accuracy",
    component: _ad84972c,
    name: "admin-child-accuracy"
  }, {
    path: "/admin/child-active-history",
    component: _5641ab7e,
    name: "admin-child-active-history"
  }, {
    path: "/admin/child-competition-details",
    component: _ceab83d4,
    name: "admin-child-competition-details"
  }, {
    path: "/admin/child-monthly-points",
    component: _80012358,
    name: "admin-child-monthly-points"
  }, {
    path: "/admin/child-points-history",
    component: _0b90b44a,
    name: "admin-child-points-history"
  }, {
    path: "/admin/competition-winner",
    component: _653dc46d,
    name: "admin-competition-winner"
  }, {
    path: "/admin/coupons",
    component: _e17ca2c0,
    name: "admin-coupons"
  }, {
    path: "/admin/create-question",
    component: _3672fd32,
    name: "admin-create-question"
  }, {
    path: "/admin/create-question-set",
    component: _1052207c,
    name: "admin-create-question-set"
  }, {
    path: "/admin/custom-ads",
    component: _6dcb04b7,
    name: "admin-custom-ads"
  }, {
    path: "/admin/daily-question-performance",
    component: _4fb76f46,
    name: "admin-daily-question-performance"
  }, {
    path: "/admin/edit-question",
    component: _6e09a919,
    name: "admin-edit-question"
  }, {
    path: "/admin/encryption",
    component: _68ee863a,
    name: "admin-encryption"
  }, {
    path: "/admin/leaderboard",
    component: _4f46284d,
    name: "admin-leaderboard"
  }, {
    path: "/admin/leaderboard-referal",
    component: _5cd0ba6e,
    name: "admin-leaderboard-referal"
  }, {
    path: "/admin/lessons",
    component: _f12b4b64,
    name: "admin-lessons"
  }, {
    path: "/admin/question-performance",
    component: _30bc4e28,
    name: "admin-question-performance"
  }, {
    path: "/admin/question-set-history",
    component: _19503abc,
    name: "admin-question-set-history"
  }, {
    path: "/admin/question-set-of-the-day",
    component: _5fc98eac,
    name: "admin-question-set-of-the-day"
  }, {
    path: "/admin/questions",
    component: _77ef2fc6,
    name: "admin-questions"
  }, {
    path: "/admin/questionSets",
    component: _28006292,
    name: "admin-questionSets"
  }, {
    path: "/admin/shop-order",
    component: _66e91b4c,
    name: "admin-shop-order"
  }, {
    path: "/admin/stories",
    component: _6401fb34,
    name: "admin-stories"
  }, {
    path: "/admin/subscriptions",
    component: _2972d349,
    name: "admin-subscriptions"
  }, {
    path: "/admin/undermaintance",
    component: _a331f09c,
    name: "admin-undermaintance"
  }, {
    path: "/admin/users",
    component: _5cc59fdb,
    name: "admin-users"
  }, {
    path: "/admin/wild-card",
    component: _19361174,
    name: "admin-wild-card"
  }, {
    path: "/advertisers/dashboard",
    component: _b77e7380,
    name: "advertisers-dashboard"
  }, {
    path: "/affiliate/sign-up",
    component: _6655990e,
    name: "affiliate-sign-up"
  }, {
    path: "/affiliate/terms-and-conditions",
    component: _77b74fd4,
    name: "affiliate-terms-and-conditions"
  }, {
    path: "/coupon-users/coupons",
    component: _ea54b982,
    name: "coupon-users-coupons"
  }, {
    path: "/history/HistoryList",
    component: _6480dec7,
    name: "history-HistoryList"
  }, {
    path: "/parents/achievements",
    component: _1f920cec,
    name: "parents-achievements"
  }, {
    path: "/parents/assessment",
    component: _3ce7f5a8,
    name: "parents-assessment"
  }, {
    path: "/parents/challenges",
    component: _0057b659,
    name: "parents-challenges"
  }, {
    path: "/parents/chat-history",
    component: _d63d4270,
    name: "parents-chat-history"
  }, {
    path: "/parents/chatTest",
    component: _39e6b670,
    name: "parents-chatTest"
  }, {
    path: "/parents/child-profile",
    component: _59e79cf6,
    name: "parents-child-profile"
  }, {
    path: "/parents/claimgift",
    component: _7050de14,
    name: "parents-claimgift"
  }, {
    path: "/parents/create-child",
    component: _53aa53f1,
    name: "parents-create-child"
  }, {
    path: "/parents/daily-questions",
    component: _02899823,
    name: "parents-daily-questions"
  }, {
    path: "/parents/digital-store",
    component: _13c69379,
    name: "parents-digital-store"
  }, {
    path: "/parents/friend-request-approval",
    component: _47532f1a,
    name: "parents-friend-request-approval"
  }, {
    path: "/parents/jump-request",
    component: _0da61856,
    name: "parents-jump-request"
  }, {
    path: "/parents/jump-test",
    component: _9960bc4a,
    name: "parents-jump-test"
  }, {
    path: "/parents/kids",
    component: _2a4abe53,
    name: "parents-kids"
  }, {
    path: "/parents/leaderboard",
    component: _076efff2,
    name: "parents-leaderboard"
  }, {
    path: "/parents/mykids",
    component: _1173a47f,
    name: "parents-mykids"
  }, {
    path: "/parents/parent-profile",
    component: _5a3cdd28,
    name: "parents-parent-profile"
  }, {
    path: "/parents/practice",
    component: _f06b7e38,
    name: "parents-practice"
  }, {
    path: "/parents/pricing",
    component: _fbe7c15a,
    name: "parents-pricing"
  }, {
    path: "/parents/social",
    component: _4dc17c16,
    name: "parents-social"
  }, {
    path: "/parents/stories",
    component: _43ca53a0,
    name: "parents-stories"
  }, {
    path: "/parents/subscription",
    component: _53b25703,
    name: "parents-subscription"
  }, {
    path: "/parents/test",
    component: _17432df8,
    name: "parents-test"
  }, {
    path: "/parents/thank-you",
    component: _1d17ff74,
    name: "parents-thank-you"
  }, {
    path: "/shop-users/orders",
    component: _5fb42f16,
    name: "shop-users-orders"
  }, {
    path: "/shop-users/products",
    component: _6c5ecc52,
    name: "shop-users-products"
  }, {
    path: "/shop/brands",
    component: _1148f950,
    name: "shop-brands"
  }, {
    path: "/shop/Brands",
    component: _7d405cb5,
    name: "shop-Brands"
  }, {
    path: "/shop/cart",
    component: _06c3e7ec,
    name: "shop-cart"
  }, {
    path: "/shop/categories",
    component: _6c5d41b0,
    name: "shop-categories"
  }, {
    path: "/shop/Categories",
    component: _9b1f76f6,
    name: "shop-Categories"
  }, {
    path: "/shop/checkout",
    component: _73c48212,
    name: "shop-checkout"
  }, {
    path: "/shop/Deals",
    component: _257fd02e,
    name: "shop-Deals"
  }, {
    path: "/shop/offers",
    component: _b028e53a,
    name: "shop-offers"
  }, {
    path: "/shop/Offers",
    component: _34f78b00,
    name: "shop-Offers"
  }, {
    path: "/shop/order-history",
    component: _37ed5b5f,
    name: "shop-order-history"
  }, {
    path: "/shop/products",
    component: _71660ea0,
    name: "shop-products"
  }, {
    path: "/admin/additional-points/create",
    component: _401c0062,
    name: "admin-additional-points-create"
  }, {
    path: "/admin/advertisements/ad-specification",
    component: _05047b0d,
    name: "admin-advertisements-ad-specification"
  }, {
    path: "/admin/advertisements/advertisers",
    component: _356f5fca,
    name: "admin-advertisements-advertisers"
  }, {
    path: "/admin/advertisements/formats",
    component: _35cee8fe,
    name: "admin-advertisements-formats"
  }, {
    path: "/admin/advertisements/units",
    component: _7f693311,
    name: "admin-advertisements-units"
  }, {
    path: "/admin/coupons/affiliate-inquiries",
    component: _c5ce041a,
    name: "admin-coupons-affiliate-inquiries"
  }, {
    path: "/admin/coupons/coupon-usage",
    component: _0ecdb448,
    name: "admin-coupons-coupon-usage"
  }, {
    path: "/admin/digital-store/trades",
    component: _63ef85f2,
    name: "admin-digital-store-trades"
  }, {
    path: "/admin/shop/masters",
    component: _50c03d18,
    name: "admin-shop-masters"
  }, {
    path: "/admin/shop/offers",
    component: _004b36ba,
    name: "admin-shop-offers"
  }, {
    path: "/admin/shop/orders",
    component: _7267cc71,
    name: "admin-shop-orders"
  }, {
    path: "/admin/shop/products",
    component: _423a0020,
    name: "admin-shop-products"
  }, {
    path: "/admin/subscriptions/create",
    component: _2c9be095,
    name: "admin-subscriptions-create"
  }, {
    path: "/admin/subscriptions/track-subscriptions",
    component: _3fbd35aa,
    name: "admin-subscriptions-track-subscriptions"
  }, {
    path: "/parents/challenges/trending",
    component: _91efc224,
    name: "parents-challenges-trending"
  }, {
    path: "/parents/challenges/winners",
    component: _7e5c1b1e,
    name: "parents-challenges-winners"
  }, {
    path: "/parents/digital-store/avatars",
    component: _4409d921,
    name: "parents-digital-store-avatars"
  }, {
    path: "/parents/digital-store/cars",
    component: _a03d9170,
    name: "parents-digital-store-cars"
  }, {
    path: "/parents/digital-store/collections",
    component: _d67fae02,
    name: "parents-digital-store-collections"
  }, {
    path: "/parents/digital-store/icons",
    component: _1bd94c7e,
    name: "parents-digital-store-icons"
  }, {
    path: "/parents/digital-store/inventory",
    component: _3cf6b074,
    name: "parents-digital-store-inventory"
  }, {
    path: "/parents/digital-store/limited-deals",
    component: _7a159a3b,
    name: "parents-digital-store-limited-deals"
  }, {
    path: "/parents/digital-store/mystry-box",
    component: _3489227b,
    name: "parents-digital-store-mystry-box"
  }, {
    path: "/parents/digital-store/new-arrivals",
    component: _44f12050,
    name: "parents-digital-store-new-arrivals"
  }, {
    path: "/parents/digital-store/orders",
    component: _6944cbce,
    name: "parents-digital-store-orders"
  }, {
    path: "/parents/digital-store/pets",
    component: _6559c646,
    name: "parents-digital-store-pets"
  }, {
    path: "/parents/digital-store/trade-offers",
    component: _22fc5ee8,
    name: "parents-digital-store-trade-offers"
  }, {
    path: "/parents/practice/opponent",
    component: _3f35968a,
    name: "parents-practice-opponent"
  }, {
    path: "/parents/practice/subjectSelector",
    component: _7601b43d,
    name: "parents-practice-subjectSelector"
  }, {
    path: "/parents/stories/storyList",
    component: _44852281,
    name: "parents-stories-storyList"
  }, {
    path: "/shop/order-history/track",
    component: _d8e18150,
    name: "shop-order-history-track"
  }, {
    path: "/parents/digital-store/inventory/Profile",
    component: _2048f406,
    name: "parents-digital-store-inventory-Profile"
  }, {
    path: "/",
    component: _354b69e2,
    name: "index"
  }, {
    path: "/parents/digital-store/trade-offers/history/:id",
    component: _48584d88,
    name: "parents-digital-store-trade-offers-history-id"
  }, {
    path: "/admin/advertisements/advertisers/:id",
    component: _27f2669c,
    name: "admin-advertisements-advertisers-id"
  }, {
    path: "/admin/advertisements/units/:id",
    component: _a9ca748e,
    name: "admin-advertisements-units-id"
  }, {
    path: "/admin/shop/offers/:id",
    component: _f84b13e4,
    name: "admin-shop-offers-id"
  }, {
    path: "/admin/shop/orders/:item_id",
    component: _77c8eb7c,
    name: "admin-shop-orders-item_id"
  }, {
    path: "/admin/shop/products/:id",
    component: _2b5aa2db,
    name: "admin-shop-products-id"
  }, {
    path: "/parents/challenges/winners/:id",
    component: _79d24d06,
    name: "parents-challenges-winners-id"
  }, {
    path: "/parents/child-profile/certificate/:id",
    component: _aef8ba10,
    name: "parents-child-profile-certificate-id"
  }, {
    path: "/parents/digital-store/collections/:id",
    component: _2562a72c,
    name: "parents-digital-store-collections-id"
  }, {
    path: "/parents/digital-store/trade-offers/:id",
    component: _90b07512,
    name: "parents-digital-store-trade-offers-id"
  }, {
    path: "/admin/additional-points/:id",
    component: _f8b85a72,
    name: "admin-additional-points-id"
  }, {
    path: "/admin/coupons/:id",
    component: _1a62798b,
    name: "admin-coupons-id"
  }, {
    path: "/admin/custom-ads/:id?",
    component: _36f0c8df,
    name: "admin-custom-ads-id"
  }, {
    path: "/admin/lessons/:id",
    component: _2272cf94,
    name: "admin-lessons-id"
  }, {
    path: "/admin/stories/:id",
    component: _4f247a51,
    name: "admin-stories-id"
  }, {
    path: "/admin/users/:id",
    component: _94755574,
    name: "admin-users-id"
  }, {
    path: "/admin/wild-card/:id",
    component: _9676019e,
    name: "admin-wild-card-id"
  }, {
    path: "/parents/challenges/:id",
    component: _02e17b01,
    name: "parents-challenges-id"
  }, {
    path: "/parents/edit-child/:id?",
    component: _a8670dac,
    name: "parents-edit-child-id"
  }, {
    path: "/parents/payment-detials/:id?",
    component: _4bd05a20,
    name: "parents-payment-detials-id"
  }, {
    path: "/parents/stories/:id",
    component: _7718be08,
    name: "parents-stories-id"
  }, {
    path: "/shop-users/orders/:item_id",
    component: _50a0b277,
    name: "shop-users-orders-item_id"
  }, {
    path: "/shop/brands/:id",
    component: _f8d99b7a,
    name: "shop-brands-id"
  }, {
    path: "/shop/categories/:id",
    component: _2d16a613,
    name: "shop-categories-id"
  }, {
    path: "/shop/offers/:id",
    component: _0cad8264,
    name: "shop-offers-id"
  }, {
    path: "/shop/products/:id",
    component: _689a3b9b,
    name: "shop-products-id"
  }, {
    path: "/admin/subscriptions/:id/edit",
    component: _4a07fb18,
    name: "admin-subscriptions-id-edit"
  }, {
    path: "/invite/:referralCode?",
    component: _e0b199ba,
    name: "invite-referralCode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
