export const state = () => ({
  level: { id: null, level: null },
  subject: null,
  subjectLevels: null,
});

export const mutations = {
  changeLevel(state, payload) {
    state.level = payload;
  },
  changeSubject(state, payload) {
    state.subject = payload;
  },
  changeSubjectLevels(state, payload) {
    state.subjectLevels = payload;
  },
  clearData(state) {
    state.level = { id: null, level: null };
    state.subject = null;
    state.subjectLevels = null;
  }
};

export const getters = {
  getLevel: state => state.level.id,
  getLevelValue: state => state.level.level,
  getSubject: state => state.subject,
  getSubjectLevels: state => state.subjectLevels
};
