
export default {
  name: "ProfileFrame",
  props: {
    child: Object,
    dpSize: { Type: [Number, String], default: 64 }
  },
  computed: {
    frameSize() {
      // +25% of profile picture
      return (this.dpSize * 125) / 100;
    }
  }
};
