export const state = () => ({
  currentGamePhase: null,
  gameInvitation: false,
  subject: {
    id: null,
    level: null
  },
  friends: [],
  opponent: null,
  question: null,
  questionCount: 0,
  totalQuestions: 0,
  submitters: [],
  summary: null,
  points: {},
  requester: null
});

export const mutations = {
  setCurrentGamePhase(state, status) {
    state.currentGamePhase = status;
  },
  setGameInvitation(state, invite) {
    state.gameInvitation = invite;
  },
  setOpponent(state, payload) {
    state.opponent = payload;
  },
  setFriends(state, data) {
    state.friends = data;
  },
  setSubject(state, data) {
    state.subject.id = data.id;
    state.subject.level = data.level;
  },
  setQuestion(state, payload) {
    state.question = payload;
  },
  setQuestionCount(state, number) {
    state.questionCount = number;
  },
  setTotalQuestions(state, number) {
    state.totalQuestions = number;
  },
  setSummary(state, data) {
    state.summary = data;
    state.points = {};
  },
  setSubmitters(state, data) {
    if (Array.isArray(data)) {
      state.submitters = data;
    } else {
      state.submitters.push(data);
    }
  },
  setQuestionAverageTime(state, time) {
    state.question = { ...state.question, averageTime: time };
  },
  resetGamePhase(state) {
    state.currentGamePhase = null;
  },
  incrementPoints(state, payload) {
    if (!state.points[payload.playerId]) {
      state.points[payload.playerId] = 0;
    }
    state.points[payload.playerId] += payload.points;
  },
  setRequester(state, requester) {
    state.requester = requester;
  }
};

export const getters = {
  getCurrentGamePhase: state => state.currentGamePhase,
  getGameInvitation: state => state.gameInvitation,
  getOpponent: state => state.opponent,
  getFriends: state => state.friends,
  getSubject: state => state.subject,
  getQuestion: state => state.question,
  getQuestionCount: state => state.questionCount,
  getTotalQuestions: state => state.totalQuestions,
  getSubmitters: state => state.submitters,
  getSummary: state => state.summary,
  getPoints: state => state.points,
  getRequester: state => state.requester
};
