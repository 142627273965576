
export default {
    data() {
        return {};
    },
    props: {
        adsData: {
            type: Object
        }
    },
    methods: {
        recordAdClick() {
            console.log('ad is clicked ==== ', this.adsData.adItemId);
            this.$api.advertisement.create('ad-clicked', {
                adItemId: this.adsData.adItemId
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            // console.log('in next tick')
        });
    },
    watch: {
        adsData() {}
    }
};
