const SERVICE_NAME = "offers";
import backend from "@/lib/backend";
export default axios => ({
  //use $paginate:false in find()
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  get(data) {
    return backend(axios, SERVICE_NAME).get(data);
  },
  getAll() {
    return backend(axios, SERVICE_NAME).getAll();
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
  patch(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, data);
  },
  delete(id) {
    return backend(axios, SERVICE_NAME).delete(id);
  }
});
