const SERVICE_NAME = "user-subject-level";
import backend from "@/lib/backend";
export default axios => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  update(id, data) {
    return backend(axios, SERVICE_NAME).update(id, data);
  },
  getJumpTestData(data) {
    return backend(axios, SERVICE_NAME).find({
      ...data,
      controller: "jump-test"
    });
  },
  getAllChildrenAssessedStatus() {
    return backend(axios, SERVICE_NAME).find({
      controller: "children-assessed-status"
    });
  },
  postJumpTestData(data) {
    return backend(axios, SERVICE_NAME).create({
      ...data,
      controller: "jump-test"
    });
  },
  subjectAssessed(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, {
      ...data,
      controller: "update-assessed"
    });
  }
});
