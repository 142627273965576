const SERVICE_NAME = "countries";
import backend from "@/lib/backend";
export default axios => ({
  //use $paginate:false in find()
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  // get(data) {
  //   return backend(axios, SERVICE_NAME).get(data);
  // }
  getAll() {
    return backend(axios, SERVICE_NAME).find();
  },
  getPaymentSupportedCountries() {
    return backend(axios, SERVICE_NAME).find({
      paymentSupported: 1,
      $paginate: false
    });
  }
});
