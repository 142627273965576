
import ProfileFrame from '@/components/Challenges/_ProfileFrame.vue';
import { mdiStar } from '@mdi/js';
import { mapGetters, mapState } from 'vuex';
import { convertLeaderboardPoints } from '@/lib/common';

export default {
  props: {
    showPoints: {
      type: Boolean,
      default: false
    },
    challenger: {
      type: Object
    }
  },
  components: { ProfileFrame },
  data() {
    return {
      icons: {
        mdiStar
      }
    };
  },
  computed: {
    ...mapState('global', ['selectedUserProfile']),
    ...mapGetters({
      _opponent: 'practiceGamesCompetition/getOpponent',
      getSubject: 'practiceGamesCompetition/getSubject',
      submitters: 'practiceGamesCompetition/getSubmitters',
      selectedSubject: 'practiceQuestionSet/getSubject',
      getChild: 'global/getChild',
      getSubjectLevels: 'childProfile/getSubjectLevels',
      points: 'practiceGamesCompetition/getPoints'
    }),
    userIndicator() {
      let user = this.submitters.find(
        obj => obj.id === this.selectedUserProfile.id
      );
      if (user) {
        return user.correct ? 'green-ring' : 'red-ring';
      }
      return 'yellow-ring';
    },
    opponent() {
      return this.challenger || this._opponent;
    },
    opponentIndicator() {
      let user = this.submitters.find(obj => obj.id === this.opponent?.id);
      if (user) {
        return user.correct ? 'green-ring' : 'red-ring';
      }
      return 'yellow-ring';
    },
    challengerLevel() {
      if (!this.getSubject.id) return 0;
      return this.getSubjectLevels[this.getSubject.id].level;
    }
  },
  methods: {
    convertTotalPoints(points) {
      const res = convertLeaderboardPoints(points);
      return res;
    }
  },
  async mounted() {}
};
