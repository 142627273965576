const SERVICE_NAME = "payment-transactions";
import backend from "@/lib/backend";
export default axios => ({
  find(data) {
    return backend(axios, `${SERVICE_NAME}`).find(data);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  }
});
