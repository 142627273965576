const SERVICE_NAME = "institutes";
import backend from "@/lib/backend";
export default axios => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  getAll() {
    return backend(axios, SERVICE_NAME).getAll();
  }
});
