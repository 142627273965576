const SERVICE_NAME = "cart";
import backend from "@/lib/backend";
export default axios => ({
  get(id, data) {
    return backend(axios, SERVICE_NAME).get(id, data);
  },
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
});
