import { io } from 'socket.io-client';
import { EVENTS } from '../../api/src/libs/practiceMultiplayer/constants';
import Vue from 'vue';
let socket = null;

export default ({ store, $auth, $config }, inject) => {
  inject('multiplayerSocket', {
    init(childId) {
      if (socket) {
        if (!socket.connected) {
          socket.connect();
        }
        return;
      } else {
        socket = io($config.CHAT_SERVER_URL, {
          path: '/practicemultiplayer',
          transports: ['websocket'],
          query: { jwtToken: $auth.strategy.token.get(), childId }
        });
      }

      socket.on('connect', () => {
        // Join Once Init Done
        // socket.emit(EVENTS.JOIN, childId)

        console.log('Multiplayer socket Successfully Initialized!');
        this.handleServerEvents(socket);

        // socket.emit(EVENTS.GET_FRIENDS);
      });
    },

    // Server Events ------------------------------

    handleServerEvents(socket) {
      socket.removeAllListeners();

      socket.on(EVENTS.GET_FRIENDS, res => {
        store.commit('practiceGamesCompetition/setFriends', res);
      });

      // Show popup
      socket.on(
        EVENTS.RECEIVE_GAME_INVITE,
        (childId, subjectId, level, opponent) => {
          if (childId) {
            store.commit('practiceGamesCompetition/setOpponent', opponent);
            store.commit('practiceGamesCompetition/setSubject', {
              id: subjectId,
              level
            });

            store.commit('practiceGamesCompetition/setGameInvitation', true);
          }
        }
      );

      // Session created
      socket.on(EVENTS.ACCEPT_GAME_INVITE, (receiver, subjectId, level) => {
        console.log('---ACCEPT_GAME_INVITE--');

        // Update subject level since receiver could've had a lower level
        store.commit('practiceGamesCompetition/setSubject', {
          id: subjectId,
          level
        });

        // store.commit(
        //   'practiceGamesCompetition/setCurrentGamePhase',
        //   'startingCountdown'
        // );
      });

      // Invite rejected
      socket.on(EVENTS.REJECT_GAME_INVITE, childId => {
        store.commit(
          'practiceGamesCompetition/setCurrentGamePhase',
          'declined'
        );
      });

      //Countdown Event
      socket.on(EVENTS.GAME_START_COUNTDOWN, totalQuestions => {
        console.log('--GAME_START_COUNTDOWN--');
        store.commit(
          'practiceGamesCompetition/setCurrentGamePhase',
          'startingCountdown'
        );
        store.commit('practiceGamesCompetition/setQuestionCount', 0);
        store.commit('practiceGamesCompetition/setQuestion', null);
        store.commit('practiceGamesCompetition/setSummary', null);
        store.commit(
          'practiceGamesCompetition/setTotalQuestions',
          totalQuestions
        );
      });

      //Start Game Event
      socket.on(EVENTS.NEW_QUESTION_RECEIVED, data => {
        const count =
          store.getters['practiceGamesCompetition/getQuestionCount'];
        // console.log('--NEW_QUESTION_RECEIVED--', data);
        store.commit('practiceGamesCompetition/setSubmitters', []);
        store.commit('practiceGamesCompetition/setQuestion', data);
        store.commit('practiceGamesCompetition/setQuestionCount', count + 1);

        store.commit(
          'practiceGamesCompetition/setCurrentGamePhase',
          'competitionStarted'
        );
      });

      //Update Question Timer Event
      socket.on(EVENTS.UPDATE_TIMER, data => {
        store.commit('practiceGamesCompetition/setQuestionAverageTime', data);
      });

      // Invite timedout
      socket.on(EVENTS.GAME_INVITE_TIMEOUT, childId => {
        const inviteSent =
          store.getters['practiceGamesCompetition/getGameInvitation'];

        if (inviteSent) {
          store.commit('practiceGamesCompetition/setGameInvitation', false);
        } else {
          store.commit(
            'practiceGamesCompetition/setCurrentGamePhase',
            'notResponded'
          );
        }
      });

      //Game Ended Event
      socket.on(EVENTS.GAME_ENDED, data => {
        store.commit('practiceGamesCompetition/setSummary', data);
        store.commit(
          'practiceGamesCompetition/setCurrentGamePhase',
          'showSummary'
        );
        store.commit('practiceGamesCompetition/setSubmitters', []);
      });

      // Correct answer submitted by someone
      socket.on(
        EVENTS.CORRECT_ANSWER_RECEIVED,
        (playerId, question, points) => {
          store.commit('practiceGamesCompetition/setSubmitters', {
            id: playerId,
            correct: true
          });
          if (points > 0) {
            store.commit('practiceGamesCompetition/incrementPoints', {
              playerId,
              points
            });
          }
        }
      );

      // Incorrect answer submitted by someone
      socket.on(
        EVENTS.INCORRECT_ANSWER_RECEIVED,
        (playerId, question, points) => {
          store.commit('practiceGamesCompetition/setSubmitters', {
            id: playerId,
            correct: false
          });
        }
      );

      socket.on(EVENTS.FRIEND_IS_IN_GAME, childId => {
        alert('Friend is in game request after some time');
        const friends = store.getters['practiceGamesCompetition/getFriends'];
        const newFriends = friends.map(f =>
          f.id === childId ? { ...f, inGame: true } : f
        );
        store.commit('practiceGamesCompetition/setFriends', newFriends);
        store.commit('practiceGamesCompetition/setCurrentGamePhase', null);
        store.commit('practiceGamesCompetition/setOpponent', null);
      });

      // Random player events
      socket.on(EVENTS.SET_OPPONENT, (opponent, subject) => {
        store.commit('practiceGamesCompetition/setOpponent', opponent);
        store.commit('practiceGamesCompetition/setSubject', subject);
      });

      socket.on(EVENTS.INCOMING_REQUEST, playerData => {
        store.commit('practiceGamesCompetition/setRequester', playerData);
      });

      socket.on(EVENTS.CLEAR_INCOMING_REQUEST, () => {
        store.commit('practiceGamesCompetition/setRequester', null);

        const gameInvite =
          store.getters['practiceGamesCompetition/getGameInvitation'];
        if (gameInvite) {
          store.commit('practiceGamesCompetition/setGameInvitation', false);
          store.commit('practiceGamesCompetition/setOpponent', null);
          store.commit('practiceGamesCompetition/setSubject', {
            id: null,
            level: null
          });
        }
      });
    },

    // Client Events -------------------------------
    getFriends() {
      socket.emit(EVENTS.GET_FRIENDS);
    },

    sendGameInvite(childId, subjectId) {
      // store.commit('practiceGamesCompetition/setOpponent', {
      //   id: childId,
      //   subjectId,
      //   level
      // });
      socket.emit(EVENTS.SEND_GAME_INVITE, childId, subjectId);
    },

    acceptGameInvite() {
      const subject = store.getters['practiceGamesCompetition/getSubject'];
      store.commit('practiceQuestionSet/setSubject', {
        id: subject.id,
        level: subject.level
      });
      store.commit('practiceGamesCompetition/setSubject', {
        id: subject.id,
        level: subject.level
      });
      // store.commit(
      //   'practiceGamesCompetition/setCurrentGamePhase',
      //   'startingCountdown'
      // );
      const opponent = store.getters['practiceGamesCompetition/getOpponent'];
      socket.emit(EVENTS.ACCEPT_GAME_INVITE, opponent.id, subject.id);
    },

    rejectGameInvite() {
      const opponent = store.getters['practiceGamesCompetition/getOpponent'];
      const subject = store.getters['practiceGamesCompetition/getSubject'];

      socket.emit(EVENTS.REJECT_GAME_INVITE, opponent.id, subject.id);
      store.commit('practiceGamesCompetition/resetGamePhase');
    },

    submitAnswer(answer) {
      socket.emit(EVENTS.ANSWER_SUBMITTED, answer);
    },

    leftGame() {
      socket.emit(EVENTS.LEFT_GAME);
    },

    disconnect() {
      socket.emit(EVENTS.FORCE_DISCONNECT);
    },

    // Random player events
    getSession(subjectId) {
      socket.emit(EVENTS.GET_SESSION, subjectId);
    },

    acceptIncomingRequest() {
      socket.emit(EVENTS.ACCEPT_INCOMING_REQUEST);
    },

    rejectIncomingRequest() {
      socket.emit(EVENTS.REJECT_INCOMING_REQUEST);
    },

    switchChild(childId) {
      socket.emit(EVENTS.SWITCH_CHILD, childId);
    }
  });
};
