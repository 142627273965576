
import { mdiFacebook, mdiInstagram, mdiWhatsapp, mdiTwitter } from "@mdi/js";

export default {
  name: "HomeFooter",
  data() {
    return {
      icons: {
        mdiFacebook,
        mdiInstagram,
        mdiWhatsapp,
        mdiTwitter
      }
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    goToPage(page) {
      this.$router.replace(page);
    }
  }
};
