const SERVICE_NAME = "club-child-inventory";
import backend from "@/lib/backend";
export default axios => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  patch(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, data);
  }
});
