
import { mdiExitToApp, mdiTicketPercentOutline } from '@mdi/js';
export default {
  middleware: ['auth', 'coupon-user-only', 'under-maintenance'],
  data: () => ({
    icons: {
      mdiExitToApp
    },
    dialog: false,
    closable: true,
    selectedItem: 0,
    items: [
      {
        text: 'Coupons Usage',
        icon: mdiTicketPercentOutline,
        url: '/coupon-users/coupons'
      }
    ],
    drawer: true
  }),
  mounted() {
    if (!this.$auth.user.email_verified) {
      // this.$router.replace("/varify-email");
      this.dialog = true;

      let currDate = new Date();
      let userCreationDate = new Date(this.$auth.user.createdAt);

      const timeDiff = Math.abs(
        userCreationDate.getTime() - currDate.getTime()
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (diffDays > 7) {
        this.closable = false;
      }
    }
  },
  methods: {
    resendLink() {
      this.$api.notify
        .sendVarificationMail({ email: this.$auth.user.email })
        .then(rsp => {
          this.snackbarText = rsp.message;
          this.snackbar = true;
          this.dialog = false;
          // alert(rsp.message)
        });
    },
    logout() {
      this.$auth.logout();
      this.$router.replace('/');
    }
  }
};
