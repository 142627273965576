import { mapGetters, mapActions, mapMutations } from "vuex";
import { events, trackEvent } from "@/lib/clevertap";
import {SUBJECT_NAME} from "@/lib/constants"

export default {
  data() {
    return {
      dataClientID:'ca-pub-5736152236128320',
      horizontalDataSlotID: '5294506972',
      leftVerticalDataSlot:'5658496725',
      rightVerticalDataSlot:'6607588645',
      leftMobViewAddSlot:'8495385387',
      rightMobViewAddSlot:'4364568689'
    };
  },
  computed: {
    ...mapGetters("global", ["getChild", "getAvatar"]),
    ...mapGetters("general", ["getSubjects"]),
    ...mapGetters("childProfile", ["getSubjectLevels"]),
    getFirstName() {
      let parent_name = this.$auth.user.parent_name.split(" ");
      return parent_name[0];
    },
    getLastName() {
      let parent_name = this.$auth.user.parent_name.split(" ");
      let lastName = parent_name[1];
      return lastName ? lastName : "";
    },
    getChilds() {
      return this.$auth?.user?.user_profile;
    },
    getInitials() {
      return this.getChild.name ? this.getChild.name.charAt(0) : "A";
    },
    totalChilds() {
      return this.getChilds ? this.getChilds.length : 0;
    }
  },
  methods: {
    addChildPoints(points, opts = {}) {
      const child = { ...this.getChild };
      child.points = child.points + points;

      if (!opts.hideJumpTestPoints) {
        let jumpTestPoints = child.jumpTestPoints + points;
        const pointsNeedForJumptest = 20000;
        if (jumpTestPoints >= pointsNeedForJumptest) {
          const counter = Math.floor(jumpTestPoints / pointsNeedForJumptest);
          const pointsDeduction = pointsNeedForJumptest * counter;
          jumpTestPoints = jumpTestPoints - pointsDeduction;
          child.jumpTestCounter = child.jumpTestCounter + counter;
        }
        child.jumpTestPoints = jumpTestPoints;
      }

      this.updateChild(child);
      this.setChild(child);

      // Track Points Earned
      const eventData = {
        child_name: child.name,
        points: points,
        date: new Date(),
        platform: "web"
      }
      if (opts.subjectId && SUBJECT_NAME[opts.subjectId]) {
        eventData.subject = SUBJECT_NAME[opts.subjectId];
      }
      trackEvent(events.POINTS_EARNED, eventData);
    },
    removeChildPoints(points) {
      const child = { ...this.getChild };
      child.points = child.points - points;
      this.updateChild(child);
      this.setChild(child);
    },
    reduceJumpTestCounter() {
      const child = { ...this.getChild };
      child.jumpTestCounter = child.jumpTestCounter - 1;
      this.updateChild(child);
      this.setChild(child);
    },
    async updateChild(child) {
      let user = JSON.parse(JSON.stringify(this.$auth.user));
      user.user_profile = user.user_profile.map(el => {
        if (el.id === child.id) {
          return child;
        }
        return el;
      });
      await this.$auth.setUser(user);
    },
    async safeSetChild(child) {
      if (child.id === this.getChild.id) {
        const dt = new Date().toLocaleDateString().toString();
        if (this.$auth.user.user_profile && dt !== this.getChild.profileUpdatedAt) {
          child = this.$auth.user.user_profile.find(
            user => user.id === child.id
          );
          if (!child) {
            child = this.$auth.user.user_profile[0];
          }
        }
      }

      await this.setChild(child);
    },
    async getUserProperties() {
      return await this.$api.users.find({
        controller: "user-properties",
        userId: this.$auth.user.id
      });
    },
    calculateCartTotalDeduction(cart) {
      const payment_amount = cart.reduce(
        (acc, c) => (acc += c.payment_amount),
        0
      );
      const points_used = cart.reduce(
        (acc, c) => (acc += c.points_used),
        0
      );
      const original_price = cart.reduce((acc, c) => acc + c.net_price, 0);

      return {
        payment_amount,
        points_used,
        original_price
      };
    },
    createCartCheckoutPayload(cart) {
      return {
        items: cart.map(c => {
          return {
            product_id: c.product_id,
            product_variation_id: c.variant_id,
            qty: c.qty,
            price: c.price,
            points: c.points,
            payment_amount: c.payment_amount,
            points_used: c.points_used,
            variant: c.variant,
            buyNow: c.buyNow ? true : false
          };
        }),
        ...this.calculateCartTotalDeduction(cart)
      };
    },
    ...mapActions("global", ["setChild"]),
    ...mapMutations("general", ["setSubjects"]),
    ...mapMutations("global", ["SET_AVATAR", "SET_SUBSCRIPTION"])
  }
};
