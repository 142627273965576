
export default {
  middleware: ["auth", "students-only", "under-maintenance"],
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Question Bank", icon: "help_outline", url: "/admin/questions" },
      { text: "Dashboard", icon: "dashboard" },
      {
        text: "Question Of The Day",
        icon: "chat_bubble_outline"
      },
      { text: "My levels", icon: "chat_bubble_outline" },
      { text: "My plans", icon: "people" },
      { text: "Create Question", icon: "format_list_numbered" },
      { text: "Setting", icon: "settings" },
      { text: "Help", icon: "help" },
      { text: "FAQ", icon: "question_answer" }
    ]
  }),
  mounted() {
    if (!this.$auth.user.email_verified) {
      this.$router.replace("/varify-email");
    }
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.replace("/");
    }
  }
};
