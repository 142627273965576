export const events = {
  LOGIN_FROM_WEB: 'LoginFromWeb',
  SIGNUP_WITH_PAYMENT: 'SignupWithPayment',
  SIGNUP_WITHOUT_PAYMENT: 'SignupWithoutPayment',
  FREE_TRIAL_USER: 'FreeTrialUser', // Signup, Plans
  COUPON_USED: 'CouponUsed', // Sign up, Plans, Gift
  COUPON_APPLIED: 'CouponApplied', // Sign up, Plans, Gift
  SIGNUP_WITH_REFFERAL: 'SignupWithRefferal',
  ADD_SUBCRIPTION: 'AddSubscription', // Sign up, Plans
  GIFT_SUBSCRIPTION: 'GiftSubscription',
  GIFT_CLAIMED: 'GiftClaimed',
  ADD_CHILD: 'AddChild',
  POINTS_EARNED: 'PointsEarned',
  INVITE_FRIEND: 'InviteFriend',
  JUMP_LEVEL_REQUEST: 'JumpLevelRequest',
  BADGE_EARNED: 'BadgeEarned',
  PRACTICE: 'Practice',
  DAILY_QUESTION_ATTEMPT: 'DailyquestionAttempt',
  ASSESSMENT_FINISHED: 'AssessmentFinished',
  SAMPLE_QUESTION: 'SampleQuestion',
  JUMP_TEST_TAKEN: 'JumpTestTaken',
  RENEWED_SUBSCRIPTION: 'RenewedSubscription',
  PURCHASE_SUBSCRIPTION: 'PurchaseSubscription',
  EXTENSION_TAKEN: 'ExtensionTaken',
  // New Events
  VISITED_CLUB: 'VisitedClub',
  VISITED_DIGITAL_STORE: 'VisitedDigitalStore',
  FEATURED_DEAL_VIEWED: 'FeatureDealViewed',
  BRAND_VIEWED: 'BrandViewed',
  CATEGORY_VIEWED: 'CategoryViewed',
  OFFER_VIEWED: 'OfferViewed',
  CART_VIEWED: 'CartViewed',
  PRODUCT_VIEWED: 'ProductViewed',
  ADDED_TO_CART: 'AddedToCart',
  BUY_NOW: 'BuyNow',
  REMOVED_FROM_CART: 'RemovedFromCart',
  PROCEED_TO_ADDRESS: 'ProceedToAddress',
  SAVE_NEW_ADDRESS: 'SaveNewAddress',
  ADDRESS_SELECTED: 'AddressSelected',
  PROCEED_TO_PAYMENT: 'ProceedToPayment',
  ORDER_PLACED: 'OrderPlaced',
  ORDER_FAILED: 'OrderFailed',
  DIGITAL_PRODUCT_VIEWED: 'DigitalProductViewed',
  DIGITAL_PURCHASE_INTENT: 'DigitalPurchaseIntent',
  DIGITAL_PURCHASE_SUCCESS: 'DigitalPurchaseSuccess',
  DIGITAL_PURCHASE_CANCEL: 'DigitalPurchaseCancel',
  TRADE_INITIATED: 'TradeInitiated',
  TRADE_OFFER_SENT: 'TradeOfferSent',
  TRADE_OFFER_RECEIVED: 'TradeOfferReceived',
  TRADE_STATUS: 'TradeStatus',
  SUBSCRIPTION_ATTEMPTED: 'SubscriptionAttempted',
  VISITED_COMPETITIONS: 'VisitedCompetitions'
};

export async function registerUserAndUpdate(user, properties = {}) {
  // console.log(properties);
  if (process.env.ENVIRONMENT === 'development') return;

  try {
    clevertap.onUserLogin.push({
      Site: {
        Name: user.parent_name, // String
        Identity: user.id, // String or number
        Email: user.email, // Email address of the user
        Phone: '+' + user.country_code + user.phone_number, // Phone (with the country code)
        Gender: 'M', // Can be either M or F
        DOB: new Date(), // Date of Birth. Date object
        ...properties,
        // optional fields. controls whether the user will be sent email, push etc.
        'MSG-email': true,
        'MSG-push': true, // Enable push notifications
        'MSG-sms': true, // Enable sms notifications
        'MSG-whatsapp': true // Enable WhatsApp notifications
      }
    });
    const timezoneOffset = new Date().getTimezoneOffset();
    clevertap.setTimezoneOffset(timezoneOffset);
  } catch (e) {
    console.log(e);
  }
}

export async function onUserLogin(user) {
  if (process.env.ENVIRONMENT === 'development') return;

  await registerUserAndUpdate(user);

  //   Track Login Event
  trackEvent(events.LOGIN_FROM_WEB, { Date: new Date() });
}

export async function trackEvent(event, params) {
  if (process.env.ENVIRONMENT === 'development') return;

  try {
    clevertap.event.push(event, params);
  } catch (e) {
    console.log(e);
  }
}
