import Vue from 'vue';
import moment from 'moment-timezone';
import { materialColors } from '../lib/material-colors.js';

export const state = () => ({
  chats: [],
  currentChat: null,
  onlineFriendsId: [],
  typingFriendsId: [],
  currentChild: {},
  userChatActivities: {},
  popUpMessage: null,
  openedTradeId: null
});

export const mutations = {
  setCurrentChild(state, payload) {
    state.currentChild = payload;
  },
  setChats(state, payload) {
    state.chats = payload;
  },
  addNewChat(state, payload) {
    state.chats.push(payload);
  },
  setCurrentChat(state, payload) {
    state.currentChat = payload;
  },
  setOnlineFriendsId(state, payload) {
    state.onlineFriendsId = payload;
  },
  addOnlineUserId(state, childId) {
    state.onlineFriendsId.push(childId);
  },
  removeOfflineUserId(state, childId) {
    state.onlineFriendsId = state.onlineFriendsId.filter(id => id != childId);
  },
  clearCurrentChatMessages(state) {
    state.currentChat = { ...state.currentChat, messages: [] };
  },
  setPopUp(state, payload) {
    state.popUpMessage = payload;
  },
  clearChat(state, { chatId }) {
    Vue.set(state.currentChat, 'messages', []);

    const index = state.chats.findIndex(chat => chat.id == chatId);
    Vue.set(state.chats[index], 'messages', []);
  },
  deleteChat(state, { chatId }) {
    state.currentChat = null;
    state.chats = state.chats.filter(c => c.id !== chatId);
  },
  deleteMessage(state, { chatId, messageId }) {
    Vue.set(
      state.currentChat,
      'messages',
      state.currentChat.messages.filter(msg => msg.id !== messageId)
    );

    const index = state.chats.findIndex(chat => chat.id == chatId);
    Vue.set(
      state.chats[index],
      'messages',
      state.chats[index].messages.filter(msg => msg.id !== messageId)
    );
  },
  addNewUsersInGroup(state, { chatId, newUsers }) {
    const { chats, currentChat } = state;
    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;

    newUsers.forEach(user => {
      user.color = getRandomColor();
    });

    Vue.set(
      state.chats[index],
      'users',
      [].concat.apply(chats[index].users, newUsers)
    );

    const isCurrentChat = currentChat && currentChat.id == chatId;

    if (!isCurrentChat) return;

    Vue.set(
      state.currentChat,
      'users',
      [].concat.apply(currentChat.users, newUsers)
    );
  },
  removeUsersFromGroup(state, { chatId, removedChildIds }) {
    const { chats, currentChat } = state;

    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;

    Vue.set(
      state.chats[index],
      'users',
      chats[index].users.filter(user => !removedChildIds.includes(user.id))
    );

    const isCurrentChat = currentChat && currentChat.id == chatId;
    if (!isCurrentChat) return;

    Vue.set(
      state.currentChat,
      'users',
      currentChat.users.filter(user => !removedChildIds.includes(user.id))
    );
  },
  setUserChatActivity(state, { chatId, lastReadMsgId }) {
    let lastSeen = state.userChatActivities[chatId];

    if (lastSeen && lastSeen.lastReadMsgId === lastReadMsgId) return;

    lastSeen = { chatId, lastReadMsgId };
    Vue.set(state.userChatActivities, chatId, lastSeen);

    this.$chatSocket.sendChatCache({
      childId: state.currentChild.id,
      chat: lastSeen
    });
  },
  resetUserChatActivities(state) {
    state.userChatActivities = {};
  },
  restoreUserChatActivities(state, { chats }) {
    state.userChatActivities = chats;
  },
  updateUserChatActivitiesFromOtherInstance(state, { chatId, lastReadMsgId }) {
    Vue.set(state.userChatActivities, chatId, { chatId, lastReadMsgId });
  },
  updateUserChatActivity(state, payload) {
    const { chatId, message } = payload;
    const { currentChat } = state;

    if (!currentChat || currentChat.id !== chatId) return;
    Vue.set(state.userChatActivities, chatId, {
      chatId,
      lastReadMsgId: message.id
    });
  },
  addNewGroup(state, payload) {
    state.chats.push(payload);

    if (payload.createdBy === state.currentChild.id)
      state.currentChat = payload;
  },
  setTypingStatus(state, { isTyping, chatId, childId, childName }) {
    const { chats, currentChat } = state;

    const updateChat = chat => {
      if (!chat.typingFriends) chat.typingFriends = [];
      if (isTyping) chat.typingFriends.push({ childId, childName });
      else
        chat.typingFriends = chat.typingFriends.filter(
          el => el.childId !== childId
        );

      return chat;
    };

    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;

    Vue.set(state.chats, index, updateChat(state.chats[index]));

    const isCurrentChat = currentChat && currentChat.id == chatId;
    if (!isCurrentChat) return;

    const updated = updateChat(currentChat);
    state.currentChat = JSON.parse(JSON.stringify(updated));
    // Vue.set(state, "currentChat", updated);
  },
  removeUserFromGroup(state, { chatId, removedByAdmin }) {
    const { chats, currentChat } = state;
    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;

    Vue.set(state.chats[index], 'removed', true);
    if (removedByAdmin) Vue.set(state.chats[index], 'removedByAdmin', true);

    const isCurrentChat = currentChat && currentChat.id == chatId;
    if (!isCurrentChat) return;

    Vue.set(state.currentChat, 'removed', true);
    if (removedByAdmin) Vue.set(state.currentChat, 'removedByAdmin', true);
  },
  setNewUserProfile(state, updatedUser) {
    const { chats, currentChat } = state;

    const modifyChat = chat => {
      let { type, user, users } = chat;

      if (type === 'direct') {
        if (user.id === updatedUser.id) chat.user = updatedUser;
        return chat;
      }

      chat.users = users.map(user => {
        if (user.id === updatedUser.id) return updatedUser;
        return user;
      });

      return chat;
    };

    state.chats = chats.map(modifyChat);
    state.currentChat = modifyChat(currentChat);
  },
  addNewMessage(state, { chatId, message }) {
    const { chats, currentChat } = state;
    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;

    Vue.set(state.chats[index], 'messages', [
      ...chats[index].messages,
      message
    ]);
    Vue.set(state.chats[index], 'timestamp', new Date().valueOf());

    const isCurrentChat =
      currentChat && currentChat.id == chatId && currentChat !== chats[index];

    if (isCurrentChat) state.currentChat.messages.push(message);
  },
  changeGroupInfo(state, { chatId, groupName, imageURL }) {
    const { chats, currentChat } = state;
    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;
    Vue.set(state.chats[index], 'groupName', groupName);
    Vue.set(state.chats[index], 'image_url', imageURL);
    const isCurrentChat = currentChat && currentChat.id == chatId;
    if (!isCurrentChat) return;
    Vue.set(state.currentChat, 'groupName', groupName);
    Vue.set(state.currentChat, 'image_url', imageURL);
  },
  handleTradeStatusChange(state, { chatId, messageId }) {
    const { chats, currentChat } = state;
    const index = chats.findIndex(chat => chat.id == chatId);
    if (index < 0) return;

    const isCurrentChat = currentChat && currentChat.id == chatId;

    if (!isCurrentChat) return;

    let messageIdx = currentChat.messages.findIndex(m => m.id === messageId);
    if (messageIdx < 0) return;

    Vue.set(
      state.currentChat.messages[messageIdx],
      'tradeStatusChanged',
      new Date().valueOf()
    );
  },
  updateUserAddedInGroup(state, { chat }) {
    const { chats, currentChat } = state;
    const index = chats.findIndex(c => c.id == chat.id);
    if (index < 0) state.chats.push(chat);
    else {
      Vue.set(state.chats[index], 'removed', false);
      Vue.set(state.chats[index], 'removedByAdmin', false);
    }

    const isCurrentChat = currentChat && currentChat.id == chat.id;
    if (!isCurrentChat) return;

    Vue.set(state.currentChat, 'removed', false);
    Vue.set(state.currentChat, 'removedByAdmin', false);
  },
  setOpenedTradeId(state, { tradeId }) {
    state.openedTradeId = tradeId;
  },
  changeTradeStatusInMessage(state, payload) {
    const { chats, currentChat } = state;
    const { tradeId, chatId, messageId, status } = payload;

    const index = chats.findIndex(c => c.id == chatId);
    if (index !== -1) {
      const chat = chats[index];
      const messages = chat.messages;

      const messageIdx = messages.findIndex(msg => msg.id === messageId);
      if (messageIdx !== -1) {
        const message = messages[messageIdx];
        const trade = message.trade;
        trade.status = status;
        Vue.set(state.chats[index], 'messages', messages);
      }
    }

    if (currentChat) {
      const messageIdx = currentChat.messages.findIndex(
        msg => msg.id === messageId
      );
      if (messageIdx !== -1) {
        const message = currentChat.messages[messageIdx];
        const trade = message.trade;
        trade.status = status;
        Vue.set(state.currentChat.messages[messageIdx], 'trade', trade);
      }
    }
  }
};

export const getters = {
  getChats: state => {
    const { chats, currentChat, userChatActivities, currentChild } = state;
    const chatCopy = JSON.parse(JSON.stringify(chats));

    return chatCopy
      .sort((a, b) => (new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1))
      .map(chat => {
        if (!chat.messages.length) return chat;

        if (currentChat && currentChat.id == chat.id) return chat;

        const lastSeen = userChatActivities[chat.id];
        let lastReadMsgId = chat.you.last_read_message_id || 0;
        chat.unreadMsgCount = null;

        if (lastSeen) lastReadMsgId = lastSeen.lastReadMsgId;

        if (lastReadMsgId !== null) {
          chat.unreadMsgCount = chat.messages.filter(m => {
            return m.child_id !== currentChild.id && m.id > lastReadMsgId;
          }).length;
        }

        if (chat.unreadMsgCount && chat.unreadMsgCount > 10)
          chat.unreadMsgCount = '10+';

        return chat;
      });
  },
  getMessagesGroupedByDate(state) {
    const dateWiseMessages = {};
    const messages = state?.currentChat?.messages || [];

    for (const message of messages) {
      const date = moment
        .tz(message.createdAt, 'YYYY-MM-DD HH:mm:ss', 'UTC')
        .tz('Asia/Kolkata')
        .format('YYYY-MM-DD');
      let msgs = dateWiseMessages[date];
      if (!msgs) {
        msgs = [];
        dateWiseMessages[date] = msgs;
      }
      msgs.push(message);
    }

    return dateWiseMessages;
  }
};

export const actions = {
  async handleReceivedMessage({ state, commit, rootState }, payload) {
    const { selectedUserProfile: currentChild } = rootState.global;
    const { chats, currentChat } = state;
    const { chatId, newChat } = payload;

    // Step 1: Check if Chat available
    let chat = chats.find(chat => chat.id == chatId);

    // Step 2: If chat not available fetch from API
    if (!chat) {
      if (newChat) chat = newChat;
      else {
        chat = await this.$api.chat.find({
          controller: 'chat-info',
          chatId,
          childId: currentChild.id,
          resetArchivedStatus: true
        });
      }

      this.dispatch('chat/addNewChat', chat);

      const isCurrentChatNewlyOpened =
        chat.type === 'direct' &&
        currentChat &&
        currentChat.user &&
        currentChat.user.id == chat.user.id;

      if (isCurrentChatNewlyOpened)
        commit('setCurrentChat', JSON.parse(JSON.stringify(chat)));

      return;
    }

    commit('addNewMessage', payload);
  },
  async updateNewUserProfile({ state, commit }, { childId }) {
    try {
      if (state.currentChild.id === childId) return;

      const res = await this.$api.userProfile.find({
        id: childId,
        $eager: '[profilePicture, avatar]'
      });

      if (res.total === 0) return;

      commit('setNewUserProfile', res.data[0]);
    } catch (e) {}
  },
  async handleUserAddedInGroup({ state, commit, rootState }, { chatId }) {
    const { chats } = state;
    const { selectedUserProfile: currentChild } = rootState.global;

    let chat = chats.find(chat => chat.id == chatId);
    if (!chat) {
      chat = await this.$api.chat.find({
        controller: 'chat-info',
        chatId,
        childId: currentChild.id,
        resetArchivedStatus: true
      });
    }

    commit('updateUserAddedInGroup', { chat });
  },

  setChats({ state, commit }, payload) {
    payload.forEach(chat => setColor(chat));
    commit('setChats', payload);
  },
  addNewChat({ state, commit }, payload) {
    setColor(payload);
    commit('addNewChat', payload);
  },
  addNewGroup({ state, commit }, payload) {
    setColor(payload);
    commit('addNewGroup', payload);
  }
};

const getRandomColor = () => {
  const minimum = 0;
  const maximum = materialColors.length;
  const randomNo = (Math.random() * (maximum - minimum + 1)) << 0;
  return materialColors[randomNo];
};

const setColor = chat => {
  if (chat.type !== 'group') return;
  chat.users.forEach(user => {
    user.color = getRandomColor();
  });
};
