export const state = () => ({
  topicId: null,
  levelId: null,
  subject: null,
  layout: 'choose-subject',
  previousLayout: null,
  summaryPoints: 0,
  badges: null,
  answerSummary: null,
  totalSummaries: 0,
  pointsSummary: {},
  totalQuestionsSets: [],
  subjectLessons: {}
});

export const mutations = {
  changeLayout(state, layout) {
    state.previousLayout = state.layout;
    state.layout = layout;
  },
  setSubject(state, data) {
    state.totalQuestionsSets = [];
    state.subject = data;
    state.topicId = null;
    state.layout = 'choose-level';
  },
  setTopic(state, id) {
    state.topicId = id;
  },
  setLevel(state, id) {
    state.topicId = null;
    state.levelId = id;
  },
  changeLevel(state, payload) {
    state.levelId = payload.levelId;
    state.subject = payload.subject;
    state.totalQuestionsSets = [];
    state.totalSummaries = 0;
    state.layout = 'practice-questionset';
  },
  setAnswerSummary(state, answerSummary) {
    state.answerSummary = answerSummary;
    if (state.layout !== 'points-earned') {
      state.layout = 'points-earned';
    }
  },
  setSummaryPoints(state, summaryPoints) {
    state.summaryPoints = summaryPoints;
    if (state.layout !== 'points-earned') {
      state.layout = 'points-earned';
    }
  },
  refreshLayout(state, keepData = false) {
    state.summaryPoints = 0;
    state.layout = 'choose-subject';
    if (!keepData) {
      state.topicId = null;
      state.subject = null;
      state.levelId = null;
      state.totalQuestionsSets = [];
      state.totalSummaries = 0;
    }
  },
  changeOnlyLevel(state, data) {
    state.topicId = null;
    state.levelId = data;
    state.totalQuestionsSets = [];
    // state.totalSummaries = 0;
  },
  setBadges(state, data) {
    state.badges = data;
  },
  setPointsSummary(state, data) {
    state.pointsSummary = data;
  },
  setTotalQuestionsSets(state, data) {
    state.totalQuestionsSets = data;
  },
  setTotalSummaries(state, count) {
    state.totalSummaries = count;
  },
  setSubjectLessons(state, data) {
    state.subjectLessons = data;
  }
};

export const getters = {
  getLayout: state => state.layout,
  getTopic: state => state.topicId,
  getLevel: state => state.levelId,
  getSubject: state => state.subject,
  getSummaryPoints: state => state.summaryPoints,
  getBadges: state => state.badges,
  getAnswerSummary: state => state.answerSummary,
  getTotalQuestionsSets: state => state.totalQuestionsSets,
  getTotalSummaries: state => state.totalSummaries,
  getPreviousLayout: state => state.previousLayout,
  getSubjectLessons: state => state.subjectLessons
};
