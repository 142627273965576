
import user_mixin from "../mixins/user_mixin";
export default {
  mixins: [user_mixin],
  props: {
    dataSlotID: {
      type: String,
      default: ""
    }
  },
  mounted() {
    try {
      const adsbygoogle = window.adsbygoogle || [];
      if (adsbygoogle.push) {
        adsbygoogle.push({});
      }
    } catch (error) {
      console.error('Error initializing AdSense ad:', error);
    }
  },
  computed: {
    isFreeTrial() {
      const { hasFreePlan, subscription_id } = this.getChild;
      return hasFreePlan || subscription_id === 0;
    }
  }
};
