const SERVICE_NAME = "plans";
import backend from "@/lib/backend";
export default axios => ({
  //use $paginate:false in find()
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  get(id) {
    return backend(axios, SERVICE_NAME).get(id);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
  patch(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, data);
  },
  delete(id) {
    return backend(axios, SERVICE_NAME).delete(id);
  },
  findByCountryId(id) {
    return backend(axios, SERVICE_NAME).find({
      $eager: "[planRates]",
      $joinRelation: "planRates",
      "planRates.country_id": id
    });
  },
  getAll() {
    return backend(axios, SERVICE_NAME).getAll();
  }
});
