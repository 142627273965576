const initialState = {
  "Real world": false,
  English: false,
  Maths: false,
};

export const state = () => ({
  practiceEventsTrackStatus: initialState,
});

export const mutations = {
  async setPracticeEventsTracked(state, payload) {
    state.practiceEventsTrackStatus[payload.subject] = true;
  },
  async resetPracticeEventsTracked(state) {
    state.practiceEventsTrackStatus = initialState;
  },
};

export const getters = {
  getPracticeEventsTrackStatus: (state) => state.practiceEventsTrackStatus,
};
