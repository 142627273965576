
import { mdiFacebook, mdiTwitter, mdiInstagram, mdiWhatsapp } from "@mdi/js";
export default {
  data() {
    return {
      company: [
        { name: "About Us", url: "/aboutUs" },
        {
          name: "Blog",
          url: "https://www.braingymjr.com/blog/",
          router: false
        },
        { name: "Refund Policy", url: "/returnpolicy" },
        { name: "Privacy Policy", url: "/privacypolicy" },
        { name: "Press Room", url: "/press-releases" },
        { name: "Terms & conditions", url: "/termsandcondition" }
      ],
      worksheet: [
        { name: "Math", url: "/math-worksheet" },
        { name: "English", url: "/english-worksheet" },
        { name: "Class 2 Worksheets", url: "/class-2-worksheets" }
      ],
      socialMedia: [
        {
          name: "facebook",
          href: "https://www.facebook.com/BrainGymJr",
          icon: mdiFacebook
        },
        {
          name: "twitter",
          href: "https://twitter.com/BrainGym_jr",
          icon: mdiTwitter
        },
        {
          name: "instagram",
          href: "https://www.instagram.com/braingymjr/",
          icon: mdiInstagram
        },
        {
          name: "whatsapp",
          href:
            "https://api.whatsapp.com/send/?phone=%2B919321539400&text&type=phone_number&app_absent=0",
          icon: mdiWhatsapp
        }
      ]
    };
  },
  methods: {
    goToPage(path) {
      if (path.url && path.router !== false) this.$router.replace(path.url);
      else {
        window.location.href = path.url;
      }
    }
  }
};
