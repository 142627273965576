
import { mapState } from "vuex";
import user_mixin from "@/mixins/user_mixin";

export default {
  name: "PopupMessage",
  mixins: [user_mixin],
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapState("chat", ["popUpMessage"])
  },

  watch: {
    popUpMessage(popUpMessage) {
      if (popUpMessage) {
        this.dialog = true;
        this.$chatSocket.ackPopUp({ childId: this.getChild?.id });
      }
    }
  },
  methods: {
    goToDownloadPage(item) {
      this.dialog = false;
      this.$router.push(
        "/parents/child-profile/certificate/" + item.certificate.id
      );
    }
  }
};
