
import { convertLeaderboardPoints } from '@/lib/common';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import ProgressBar from '../components/ProgressBar.vue';
import user_mixin from '~/mixins/user_mixin';
import HomeFooter from '../components/home/HomeFooter.vue';
import { onUserLogin, registerUserAndUpdate } from '@/lib/clevertap';
import PopupMessage from '@/components/popup-dialog/PopupMessage';
import PlayersInfo from '@/components/practice-questionset/Games/PlayersInfo';
import {
  mdiMenu,
  mdiSegment,
  mdiAccountMultiple,
  mdiTrophy,
  mdiPodium,
  mdiMessageText,
  mdiPuzzleEdit,
  mdiReceipt,
  mdiGift,
  mdiBookSettingsOutline,
  mdiHelpCircle,
  mdiFrequentlyAskedQuestions,
  mdiCartOutline,
  mdiToyBrickOutline,
  mdiForumOutline,
  mdiStar,
  mdiChevronDown,
  mdiAccountConvertOutline,
  mdiAccountOutline,
  mdiWechat,
  mdiCloseBox,
  mdiHumanFemaleGirl,
  mdiControllerClassic
} from '@mdi/js';
import moment from 'moment-timezone';
export default {
  name: 'parents',
  mixins: [user_mixin],
  middleware: [
    'deepLink',
    'school-redirect',
    'auth',
    'parents-only',
    'under-maintenance'
  ],
  components: {
    ProgressBar,
    HomeFooter,
    PopupMessage,
    PlayersInfo
  },
  watch: {
    isSidebarOpen(v) {
      this.drawer = !v;
    },
    drawer(v) {
      // if (!v) this.isSidebarOpen = true;
    },
    getChild: function () {
      this.imageURL = this.getAvatar;
    },
    $route(to, from) {
      const previousRouteIncludesOpponent = from.fullPath.includes('/opponent');
      const newRouteIncludesOpponent = to.fullPath.includes('/opponent');

      if (previousRouteIncludesOpponent && !newRouteIncludesOpponent) {
        this.$multiplayerSocket.leftGame();
      }
    }
  },
  data: () => ({
    navItems: [],
    drawer: false,
    isSidebarOpen: true,
    isSuccess: false,
    isFirstLogin: false,
    selectedItem: null,
    dialog: false,
    closable: true,
    jumpRequest: false,
    childProfileDialog: false,
    isMobile: false,
    imageURL: '',
    items: [
      { text: 'My Kids', icon: mdiAccountMultiple, url: '/parents/mykids' },
      {
        text: 'Leaderboard',
        icon: mdiTrophy,
        url: '/parents/leaderboard',
        isChild: true
      },
      {
        name: 'challenges',
        url: '/parents/challenges',
        text: 'Challenges',
        icon: mdiPodium
      },
      {
        text: 'Daily Questions',
        icon: mdiMessageText,
        url: '/parents/daily-questions',
        isChild: true
      },
      {
        text: 'Practice',
        icon: mdiPuzzleEdit,
        url: '/parents/practice',
        isChild: true
      },
      {
        text: '1v1 Challenge',
        icon: mdiControllerClassic,
        url: '/parents/practice/subjectSelector',
        isChild: true
      },
      {
        text: 'Stories',
        icon: mdiHumanFemaleGirl,
        url: '/parents/stories',
        isChild: true
      },
      {
        text: 'Toy Zone',
        icon: mdiToyBrickOutline,
        url: '/parents/digital-store',
        isChild: true
      },
      {
        text: 'Shop',
        icon: mdiCartOutline,
        url: '/shop',
        isChild: true
      },
      {
        text: 'My Plan',
        icon: mdiReceipt,
        url: '/myplan',
        isChild: true
      },
      {
        text: 'Gift a Subscription',
        icon: mdiGift,
        url: '/giftsubscription'
      },
      {
        text: 'Subscription',
        icon: mdiBookSettingsOutline,
        url: '/parents/subscription',
        isChild: true
      },
      { text: 'Contact Us', icon: mdiHelpCircle, url: '/contact-us' },
      { text: 'FAQ', icon: mdiFrequentlyAskedQuestions, url: '/faq' }
      /*{
        text: "Chat History",
        icon: mdiWechat,
        url: "/parents/chat-history"
      },*/
    ],

    icons: {
      mdiMenu,
      mdiSegment,
      mdiForumOutline,
      mdiStar,
      mdiChevronDown,
      mdiAccountConvertOutline,
      mdiAccountOutline,
      mdiCloseBox
    },
    supportedCountries: [91],
    country_code: 'IN',
    mini: false
  }),
  async mounted() {
    // const shopIndex = this.items.findIndex(el => el.text === "Shop");
    // const supportedCountry = this.supportedCountries.includes(
    //   this.$auth.user.country_code
    // );
    // if (!supportedCountry) this.items.splice(shopIndex, 1);

    // Connect and Join with Chat Socket
    if (this.selectedUserProfile) {
      this.$chatSocket.init(this.selectedUserProfile.id);
      this.$multiplayerSocket.init(this.selectedUserProfile.id);
    }

    try {
      this.isMobile = window.innerWidth < 600;
      this.safeSetChild(this.getChild);

      this.$api.countries
        .find({
          id: this.$auth.user.country_id
        })
        .then(data => {
          this.country_code = data.data[0].country_code;
        });
      if (this.$route.params.newUser) {
        this.isSuccess = true;
        this.isFirstLogin = true;
      }
      if (!this.$auth.user.email_verified) {
        this.dialog = true;

        let currDate = new Date();
        let userCreationDate = new Date(this.$auth.user.createdAt);

        const timeDiff = Math.abs(
          userCreationDate.getTime() - currDate.getTime()
        );
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays > 7) {
          this.closable = false;
        }
      }
      // if (this.getChild) {
      //   this.setChild(this.getChild);
      this.fetchSubjectLevels(this.getChild.id);
      // }
      const subjects = await this.$api.subjects.getAll();
      this.setSubjects(subjects.data);
      this.imageURL = this.getAvatar;
      //Clevartap
      if (!this.getClevertapSession) {
        this.startClevertapSession();
      }

      this.pushUserPropertiesToClevertap();
      this.setChildProfileHash();
      this.fetchAdModules();
    } catch (error) {
      console.log('error', error);
    }
  },
  methods: {
    goHome() {
      if (!this.$auth.loggedIn) {
        this.$router.replace('/');
      }
    },
    ...mapActions('global', ['setChild', 'fetchAdModules']),
    ...mapActions('general', ['setChildProfileHash']),
    ...mapMutations({
      setSubjects: 'general/setSubjects',
      changeSubjectLevels: 'childProfile/changeSubjectLevels',
      setCleverTapSession: 'global/setCleverTapSession',
      setUserLastUpdatedOnClevertap: 'global/setUserLastUpdatedOnClevertap',
      resetGlabalState: 'global/resetState',
      resetPracticeEventsTracked: 'analytics/resetPracticeEventsTracked',
      setShopCurrency: 'global/setShopCurrency',
      setGameInvitation: 'practiceGamesCompetition/setGameInvitation',
      changeLayout: 'practiceQuestionSet/changeLayout',
      refreshLayout: 'practiceQuestionSet/refreshLayout'
    }),
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    convertPoints(points) {
      const res = convertLeaderboardPoints(points);
      return res;
    },
    async acceptInvitation() {
      this.setGameInvitation(false);
      this.changeLayout('practice-questionset');
      if (this.$route.path !== '/parents/practice/opponent') {
        await this.$router.replace('/parents/practice/opponent');
      }
      this.$multiplayerSocket.acceptGameInvite();
    },
    rejectInvitation() {
      this.setGameInvitation(false);
      this.$multiplayerSocket.rejectGameInvite();
    },
    goToPage(link) {
      // check child is required to be present
      if (!!link.isChild && !this.getChilds) {
        this.childProfileDialog = true;
      } else {
        if (this.$route.path !== link.url) {
          this.$router.replace(link.url);
        }
      }
    },
    async onItemClick(link) {
      if (link === 'logout') {
        this.$chatSocket.onLogout();
        this.$multiplayerSocket.disconnect();
        localStorage.removeItem('vuex');
        this.resetPracticeEventsTracked();
        await this.$auth.logout();
        this.resetGlabalState();
        if (this.userType === 'Normal') {
          this.$router.replace('/');
        } else if (this.userType === 'Paytm-Mini-User') {
          this.$router.replace('/paytmlanding');
        }
      } else {
        if (this.$route.path !== link) {
          this.$router.replace(link);
        }
      }
    },
    goToChildProfile(child) {
      this.safeSetChild(child);
      //this.setChild(child);
      this.fetchSubjectLevels(child.id);

      this.$chatSocket.onSwitchChild(child.id);
      this.$multiplayerSocket.switchChild(child.id);
      this.onItemClick('/parents/child-profile');
    },

    logout() {
      this.$auth.logout();
      this.$router.replace('/');
    },
    async fetchSubjectLevels(id) {
      if (id) {
        const resp = await this.$api.userSubjectLevel.find({
          user_profile_id: id
        });
        let subjectLevels = {};
        await resp.forEach(obj => {
          subjectLevels[obj.subject_id] = {
            id: obj.level_id,
            level: obj.level
          };
        });
        this.changeSubjectLevels(subjectLevels);
      }
    },
    redirectToCreateProfile() {
      this.childProfileDialog = false;
      this.$router.replace('/parents/create-child');
    },
    async startClevertapSession() {
      await onUserLogin(this.$auth.user);
      this.setCleverTapSession(true);
    },
    async pushUserPropertiesToClevertap() {
      const currentDate = moment().format('DD-MM-YYYY');
      if (
        !this.userLastUpdatedOnClevertap ||
        this.userLastUpdatedOnClevertap !== currentDate
      ) {
        const userProps = await this.getUserProperties();
        await registerUserAndUpdate(this.$auth.user, userProps);
        this.setUserLastUpdatedOnClevertap(currentDate);
      }
    }
  },
  computed: {
    ...mapGetters('general', ['getAppProgressbarStatus', 'getSubjects']),
    ...mapGetters('global', ['getClevertapSession']),
    ...mapState('global', [
      'selectedUserProfile',
      'userType',
      'userLastUpdatedOnClevertap'
    ]),
    ...mapGetters('global', ['getChild', 'getAvatar']),
    ...mapGetters({
      chats: 'chat/getChats'
    }),
    ...mapGetters({
      isInvitationDialog: 'practiceGamesCompetition/getGameInvitation',
      getCurrentGamePhase: 'practiceGamesCompetition/getCurrentGamePhase',
      getOpponent: 'practiceGamesCompetition/getOpponent',
      getSubject: 'practiceGamesCompetition/getSubject',
      getSummary: 'practiceGamesCompetition/getSummary'
    }),
    unreadChats() {
      const chats = this.chats.filter(c => c.unreadMsgCount);
      return chats.length;
    },
    getDropDownOptions() {
      let options = [
        { title: 'Parent Account', link: '/parents/parent-profile' },
        { title: 'Password', link: '/change-password' },
        // { title: "Achievements", link: "/parents/achievements" },
        { title: 'Referral', link: '/referral' },
        // { title: "Shop Orders", link: "/shop/order-history" },
        { title: 'Logout', link: 'logout' }
      ];
      if (this.userType === 'Paytm-Mini-User') {
        options = options.filter(el => el.title !== 'Password');
      }
      if (this.totalChilds > 0) {
        options.splice(1, 0, {
          title: 'Profile',
          link: '/parents/child-profile'
        });
      }
      return options;
    },
    screen() {
      if (this.$vuetify.breakpoint.xs) {
        const areas = `"header header" "sidenav main"`;
        const width = '0px 1fr';

        return { areas, width };
      }
      const areas = this.isSidebarOpen
        ? `"header header" "sidenav main"`
        : `"header header" "sidenav main"`;

      const width = this.isSidebarOpen ? '240px 1fr' : '60px 1fr';

      return { areas, width };
    },
    userName() {
      if (this.$auth && this.$auth.user) {
        const name = this.$auth.user.parent_name;

        return name
          .split(' ')
          .map(l => l.charAt(0))
          .join('');
      }
      return '';
    },
    multiplayerPlayAgainInvite() {
      return (
        this.isInvitationDialog &&
        this.getCurrentGamePhase === 'showSummary' &&
        typeof this.getSummary[this.getOpponent.id] !== 'undefined'
      );
    },
    multiplayerSubjectName() {
      if (!this.getSubject?.id) return '??';
      return this.getSubjects.find(o => o.id === this.getSubject.id)?.name;
    }
  },
  async created() {
    if (this.$route && this.$route.path) {
      let menu = this.items.find(i => i.url == this.$route.path);
      this.selectedItem = this.items.indexOf(menu);
    }

    const childId = this.$route.query.child_id;
    if (childId) {
      const child = this.getChilds.find(c => c.id === parseInt(childId));
      if (child) {
        this.safeSetChild(child);
        this.fetchSubjectLevels(child.id);
      }
    }

    const shopCountries = await this.$api.shop.find({
      items: 'shopCountries'
    });
    const childCountry = shopCountries.find(
      el => el.id === this.$auth.user.country_id
    );
    let currency;
    if (childCountry) currency = childCountry;
    else currency = shopCountries.find(el => el.id === 841);
    this.setShopCurrency(currency);
  }
};
