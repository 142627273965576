const INVALID_MESSAGE = 'invalidMessage';
const JOIN = 'join';
const FRIENDS_ONLINE = 'friends-online';
const OFFLINE = 'offline';
const SWITCH_CHILD = 'switchChild';
const DISCONNECT = 'disconnect';
const FORCE_DISCONNECT = 'forceDisconnect';
const ONLINE = 'online';
const TYPING = 'typing';
const MESSAGE = 'message';
const MESSAGE_RECEIVED = 'messageReceived';
const NEW_CHAT = 'newChat';
const CHANGE_PROFILE_PIC = 'changeProfilePic';
const PROFILE_PIC_CHANGED = 'profilePicChanged';
const CHATS = 'chats';
const CHAT_TYPE_DIRECT = 'direct';
const CHAT_TYPE_GROUP = 'group';
const MESSAGE_TYPE_TEXT = 'text';
const MESSAGE_TYPE_TRADE = 'trade';
const MESSAGE_TYPE_QUESTION = 'question';
const DELETE_MESSAGE = 'deleteMessage';
const MESSAGE_DELETED = 'messageDeleted';
const DELETE_CHAT = 'deleteChat';
const CHAT_DELETED = 'chatDeleted';
const CLEAR_CHAT = 'clearChat';
const CHAT_CLEARED = 'chatCleared';
const KEEP_ALIVE = 'KeepAlive';
// Group
const GROUP_CREATED = 'groupCreated';
const ADD_CHILD_IN_GROUP = 'addChildInGroup';
const ADDED_IN_GROUP = 'addedInGroup';
const CHILD_ADDED_IN_GROUP = 'childAddedInGroup';
const REMOVE_CHILD_FROM_GROUP = 'removeChildFromGroup';
const REMOVED_FROM_GROUP = 'removedFromGroup';
const CHILD_REMOVED_FROM_GROUP = 'childRemovedFromGroup';
const GROUP_REMOVED = 'groupRemoved';
const GROUP_INFO_CHANGED = 'groupInfoChanged';
// Chat States
const CHAT_CACHE = 'chatCache';
const SAVE_CHAT_CACHE = 'saveChatCache';
const CHAT_CACHE_UPDATED = 'chatCacheUpdated';

// Integrations
const ASK_A_QUESTION = 'askAQuestion';
const TradeUpdate = 'tradeUpdate';
const TradeStatusChange = 'tradeStatusChange';
const POP_UP_MESSAGE = 'popUpMessage';
const POP_UP_MESSAGE_ACK = 'popUpMessageAck';
const POINTS_UPDATED = 'pointsUpdated';
const TRADE_ACTION = 'TradeAction';
const UPDATE_SENDER_TRADE_MESSAGE = 'updateSenderTradeMessage';

const PAYMENT = 'payment';

module.exports = {
  INVALID_MESSAGE,
  JOIN,
  FRIENDS_ONLINE,
  OFFLINE,
  SWITCH_CHILD,
  DISCONNECT,
  ONLINE,
  TYPING,
  MESSAGE,
  NEW_CHAT,
  ADD_CHILD_IN_GROUP,
  CHILD_ADDED_IN_GROUP,
  ADDED_IN_GROUP,
  REMOVE_CHILD_FROM_GROUP,
  REMOVED_FROM_GROUP,
  CHILD_REMOVED_FROM_GROUP,
  CHANGE_PROFILE_PIC,
  PROFILE_PIC_CHANGED,
  CHAT_TYPE_DIRECT,
  CHAT_TYPE_GROUP,
  MESSAGE_TYPE_TEXT,
  MESSAGE_TYPE_TRADE,
  MESSAGE_TYPE_QUESTION,
  CHATS,
  FORCE_DISCONNECT,
  DELETE_MESSAGE,
  MESSAGE_DELETED,
  DELETE_CHAT,
  CHAT_DELETED,
  CLEAR_CHAT,
  CHAT_CLEARED,
  MESSAGE_RECEIVED,
  GROUP_CREATED,
  SAVE_CHAT_CACHE,
  CHAT_CACHE,
  CHAT_CACHE_UPDATED,
  ASK_A_QUESTION,
  TradeUpdate,
  GROUP_REMOVED,
  GROUP_INFO_CHANGED,
  TradeStatusChange,
  KEEP_ALIVE,
  POP_UP_MESSAGE,
  POP_UP_MESSAGE_ACK,
  POINTS_UPDATED,
  PAYMENT,
  TRADE_ACTION,
  UPDATE_SENDER_TRADE_MESSAGE
};
