export const state = () => ({
  color: "success",
  content: null
});

export const actions = {};

export const mutations = {
  showMessage(state, payload) {
    state.content = payload.content;
    state.color = payload.color;
  }
};
