const SERVICE_NAME = 'child-subscription';
import backend from "@/lib/backend";
export default axios => ({
    find(data) {
        return backend(axios, `${SERVICE_NAME}`).find(data);
    },
    create(data) {
        return backend(axios, SERVICE_NAME).create(data);
    },
    update(id, data) {
        return backend(axios, SERVICE_NAME).update(id, data);
    }
});
