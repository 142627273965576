const SERVICE_NAME = "wildcard";
import backend from "@/lib/backend";
export default axios => ({
  getAll() {
    return backend(axios, SERVICE_NAME).getAll();
  },
  get(id) {
    return backend(axios, SERVICE_NAME).get(id);
  },
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
  patch(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, data);
  },
  delete(id) {
    return backend(axios, SERVICE_NAME).delete(id);
  }
});
