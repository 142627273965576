const SERVICE_NAME = 'notify';
import backend from "@/lib/backend";
export default axios => ({
    sendVarificationMail(data){
        return backend(axios, `${SERVICE_NAME}/sendEmailVarification`).create(data);
    },
    find(data) {
        return backend(axios,  `${SERVICE_NAME}/sendEmailVarification`).find(data);
    },
    create(data) {
      return backend(axios, SERVICE_NAME).create(data);
    },
});
