const SERVICE_NAME = "jump-test";
import backend from "@/lib/backend";
export default axios => ({
  getJumpTest(data) {
    const { subjectLevelId, subjectId, attendedSetsId, jumpTestId } = data;
    return backend(axios, SERVICE_NAME).find({
      levelId: subjectLevelId,
      subjectId: subjectId,
      attendedSetsId: attendedSetsId,
      jumpTestId
    });
  },
  startJumpTest(data) {
    const { childId, subjectId, monthlyTest } = data;
    return backend(axios, SERVICE_NAME).create({
      childId,
      subjectId,
      controller: "start-jump-test",
      monthlyTest
    });
  },
  submitJumpTest(data) {
    const { questionSets, childId, subjectId, levelId, jumpTestId } = data;
    return backend(axios, SERVICE_NAME).create({
      questionSets,
      childId,
      subjectId,
      levelId,
      jumpTestId
    });
  }
});
