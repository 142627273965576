const SERVICE_NAME = 'v2/daily-questions';
import backend from '@/lib/backend';
export default axios => ({
  find(data) {
    return backend(axios, `${SERVICE_NAME}`).find(data);
  },
  submitAnswer(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
  getAttemptedStatus(data) {
    return backend(axios, SERVICE_NAME).find({
      ...data,
      controller: 'attempted-status'
    });
  }
});
