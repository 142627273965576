const SERVICE_NAME = 'v2/practice-questionset';
import backend from '@/lib/backend';
export default axios => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  submitAnswer(data) {
    return backend(axios, SERVICE_NAME).create(data);
  }
});
