const SERVICE_NAME = 'leaderboard';
import backend from "@/lib/backend";
export default axios => ({
    getCategory(options) {
        return backend(axios, SERVICE_NAME).find(options);
    },
    patch(id,data){
        return backend(axios, SERVICE_NAME).patch(id, data);
    },
    getHistory(options) {
        return backend(axios, SERVICE_NAME).history(options);
    }
});