export const state = () => ({
  paymentDisable: false,
  paymentDisableMessage: "Payment Disabled"
});

export const actions = {};

export const mutations = {
  setPaymentDisable(state, payload) {
    state.paymentDisable = payload;
  }
};

export const getters = {};
