const SERVICE_NAME = "newsletter-subscribers";
import backend from "@/lib/backend";
export default axios => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
});
