
import { validationMixin } from "vuelidate";

export default {
  name: "SignInSignUp",
  mixins: [validationMixin],
  props: ["show", "redirectTo", "closeModel", "defaultPlan"],
  components: {
    CreateAccount: () => import("@/components/createAccountNew1.vue")
  },
  data() {
    return {
      //   isSelectModel: false,
      isSingInModel: false,
      isSingUpModel: false
    };
  },
  methods: {
    goToSignIn() {
      this.isSingUpModel = false;
      this.isSingInModel = true;
    },
    goToSingUp() {
      this.isSingInModel = false;
      this.isSingUpModel = true;
    },
    closeSignUp() {
      this.closeModel();
      this.isSingUpModel = false;
    },
    closeSignIn() {
      this.closeModel();
      this.isSingInModel = false;
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
