import { generateRandomString } from '@/lib/common';

export const state = () => ({
  selectedUserProfile: null,
  jumpTestDialogLastSeenOn: [],
  userType: 'Normal',
  clevertapSessionCreated: false,
  userLastUpdatedOnClevertap: null,
  cart: [],
  dailyQuestionsAttended: {},
  breadcrumb: [],
  storiesAttended: {},
  shopCurrency: null,
  lastTradesUpdate: null,
  unregisteredUserId: null,
  modules: []
});

export const actions = {
  async setChild({ commit }, payload) {
    if (payload.profile_picture_id > 0) {
      const result = await this.$api.uploads.get(payload.profile_picture_id);
      if (result) {
        payload.avatar = '/' + result.url;
      }
    }
    commit("SETCHILD", payload);
  },
  async fetchAdModules({ commit }) {
    const resp = await this.$api.advertisement.get('ad-module-path-mapping');
    commit("setModules", resp);
  }
};

export const mutations = {
  ADDCOUNTER(state, payload) {
    state.counter += payload;
  },
  SETCHILD(state, payload) {
    state.selectedUserProfile = {
      ...payload,
      profileUpdatedAt: new Date().toLocaleDateString(),
    };
  },
  UPDATE_POINTS(state, payload) {
    state.selectedUserProfile = {
      ...state.selectedUserProfile,
      points: payload,
    };
  },
  UPDATE_TRADES(state, payload) {
    state.lastTradesUpdate = new Date().toString();
  },
  SET_AVATAR(state, payload) {
    state.selectedUserProfile.avatar = payload.url;
    state.selectedUserProfile.profile_picture_id = payload.profile_picture_id;
  },
  SET_SUBSCRIPTION(state, id) {
    state.selectedUserProfile.subscription_id = id;
  },
  setChildJumpTestLastSeen(state, payload) {
    let jumpTestDialogLastSeenOn = state.jumpTestDialogLastSeenOn;
    const childInfo = jumpTestDialogLastSeenOn.find(
      (el) => el.child_id === payload.child_id
    );
    if (childInfo) {
      jumpTestDialogLastSeenOn = state.jumpTestDialogLastSeenOn.map(
        (el) => el.child_id === payload.child_id
      );
    } else {
      jumpTestDialogLastSeenOn.push(payload);
    }
    state.jumpTestDialogLastSeenOn = jumpTestDialogLastSeenOn;
  },
  setUserType(state, payload) {
    state.userType = payload;
  },
  setCleverTapSession(state, payload) {
    state.clevertapSessionCreated = payload;
  },
  setUserLastUpdatedOnClevertap(state, payload) {
    state.userLastUpdatedOnClevertap = payload;
  },
  resetState(state) {
    state.userLastUpdatedOnClevertap = null;
    state.clevertapSessionCreated = false;
  },
  setDailyQuestionAttended(
    state,
    {
      childId,
      subjectId,
      questionSetId,
      questionId,
      attendedFor,
      answer,
      timeTaken,
    }
  ) {
    let childData = state.dailyQuestionsAttended[childId];

    if (childData) {
      let attended = childData[subjectId];
      if (
        attended &&
        attended.questionSetId === questionSetId &&
        attended.attendedFor === attendedFor
      ) {
        attended.questions.push({ questionId, answer, timeTaken });
      } else {
        attended = {
          questions: [{ questionId, answer, timeTaken }],
          questionSetId,
          attendedFor,
        };
      }

      childData[subjectId] = attended;
      state.dailyQuestionsAttended[childId] = childData;
      return;
    }

    state.dailyQuestionsAttended[childId] = {
      [subjectId]: {
        questions: [{ questionId, answer, timeTaken }],
        questionSetId,
        attendedFor,
      },
    };
  },
  removeDailyQuestionAttended(state, { childId, subjectId }) {
    if (
      state.dailyQuestionsAttended[childId] &&
      state.dailyQuestionsAttended[childId][subjectId]
    )
      delete state.dailyQuestionsAttended[childId][subjectId];
  },
  setCurrentLocation(state, payload) {
    if (payload.pageLevel === 1) state.breadcrumb = [];
    if (state.breadcrumb.length < payload.pageLevel) {
      state.breadcrumb.push({ ...payload, disabled: true });
    } else if (state.breadcrumb.length > payload.pageLevel) {
      state.breadcrumb.splice(payload.pageLevel);
    }

    const currentLocationIndex = state.breadcrumb.length - 1;
    const currentLocation = state.breadcrumb[currentLocationIndex];
    if (!currentLocation.disabled)
      state.breadcrumb[currentLocationIndex] = {
        ...currentLocation,
        disabled: true,
      };

    if (state.breadcrumb.length >= 2) {
      const prevLocationIndex = state.breadcrumb.length - 2;
      const prevLocation = state.breadcrumb[prevLocationIndex];
      state.breadcrumb[prevLocationIndex] = {
        ...prevLocation,
        disabled: !prevLocation.clickable,
      };
    }
  },
  setAttendedStory(
    state,
    {
      childId,
      storyId,
      questionSetId,
      answer,
      storyline,
      points,
      completed,
      resetCurrentSet,
      currentQuestionSetPoints,
      correctAnswersInFirstAttempt,
      wrongAnswerGivenInCurrentSet,
      currentSetStatus,
      lastSavedOn,
    }
  ) {
    const createLine = ({
      storyline,
      questionSetId,
      answer,
      currentQuestionSetPoints,
      wrongAnswerGivenInCurrentSet,
    }) => {
      const line = {
        index: storyline,
        partial: questionSetId ? true : false,
      };
      if (questionSetId) {
        line.questionSetId = questionSetId;
        line.answers = answer ? [answer] : [];
        if (currentQuestionSetPoints)
          line.currentQuestionSetPoints = currentQuestionSetPoints;
        if (wrongAnswerGivenInCurrentSet)
          line.wrongAnswerGivenInCurrentSet = wrongAnswerGivenInCurrentSet;
      }
      return line;
    };

    let childStories = state.storiesAttended[childId];
    if (!childStories) {
      childStories = {};
      state.storiesAttended[childId] = childStories;
    }

    let story = childStories[storyId];
    if (!story) {
      story = {
        points: 0,
        duration: 0,
        lines: [],
      };
      childStories[storyId] = story;
    }

    story.points = points;
    story.duration += Date.now() - lastSavedOn;
    story.currentLine = storyline;

    let line = story.lines.find((el) => el.index === storyline);
    if (line) {
      if (questionSetId) {
        if (completed) line.partial = false;
        else if (resetCurrentSet) line.answers = [];
        else if (answer) line.answers.push(answer);
        if (currentQuestionSetPoints)
          line.currentQuestionSetPoints = currentQuestionSetPoints;
        if (wrongAnswerGivenInCurrentSet)
          line.wrongAnswerGivenInCurrentSet = wrongAnswerGivenInCurrentSet;
      }
    } else {
      story.lines.push(
        createLine({
          storyline,
          questionSetId,
          answer,
          currentQuestionSetPoints,
          wrongAnswerGivenInCurrentSet,
        })
      );
    }
    if (correctAnswersInFirstAttempt)
      story.correctAnswersInFirstAttempt = JSON.parse(
        JSON.stringify(correctAnswersInFirstAttempt)
      );

    if (wrongAnswerGivenInCurrentSet)
      story.wrongAnswerGivenInCurrentSet = wrongAnswerGivenInCurrentSet;
  },
  removeAttendedStory(state, { childId, storyId }) {
    delete state.storiesAttended[childId][storyId];
  },
  setShopCurrency(state, payload) {
    state.shopCurrency = payload;
  },
  setUnregisteredUserId(state) {
    if (!state.unregisteredUserId) {
      const randomId = generateRandomString();
      state.unregisteredUserId = `unregistered_user_${randomId}`;
    }
  },
  setModules(state, payload) {
    state.modules = payload;
  }
};

export const getters = {
  getChild: (state, context, store) => {
    let currentChildProfile = null;
    let subscription = false;
    if (
      state.selectedUserProfile &&
      store.auth.user &&
      store.auth.user.user_profile
    ) {
      currentChildProfile = store.auth.user.user_profile.find(
        (profile) => profile.id === state.selectedUserProfile.id
      );
    } else if (store.auth.user && store.auth.user.user_profile) {
      currentChildProfile = store.auth.user.user_profile[0];
    } else {
      currentChildProfile = {
        id: state.unregisteredUserId
      };
    }
    if (
      currentChildProfile.subscription_id &&
      currentChildProfile.subscription_id > 0
    ) {
      subscription = true;
    }
    return { ...currentChildProfile, subscription };
  },
  getAvatar: (state) => {
    return state.selectedUserProfile &&
      state.selectedUserProfile.profile_picture_id > 0
      ? state.selectedUserProfile.avatar
      : '';
  },
  getJumpTestDialogLastSeenOn: (state) => {
    return state.jumpTestDialogLastSeenOn;
  },
  getClevertapSession: (state) => {
    return state.clevertapSessionCreated;
  },
};
