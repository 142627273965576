
import HomeFooter from "../components/home/HomeFooter.vue";
import user_mixin from "~/mixins/user_mixin";
import { mapState } from "vuex";
import {
  mdiAccountMultiple,
  mdiTrophy,
  mdiPodium,
  mdiMessageText,
  mdiPuzzleEdit,
  mdiReceipt,
  mdiGift,
  mdiHelpCircle,
  mdiForum,
  mdiBookSettingsOutline,
  mdiAccountOutline,
  mdiAccountConvertOutline,
  mdiHumanFemaleGirl,
  mdiToyBrickOutline,
  mdiCartOutline
} from "@mdi/js";
export default {
  name: "Sidebar",
  mixins: [user_mixin],
  coomponents: {
    HomeFooter
  },
  props: ["onSelect", "toggleSidebar", "isSidebarOpen"],
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiAccountConvertOutline
    },
    selectedItem: null,
    items: [
      {
        name: "people",
        url: "/parents/mykids",
        text: "My Kids",
        icon: mdiAccountMultiple
      },
      {
        name: "leaderboard",
        url: "/parents/leaderboard",
        text: "Leaderboard",
        icon: mdiTrophy
      },
      // {
      //   name: "challenges",
      //   url: "/parents/challenges",
      //   text: "Challenges",
      //   icon: mdiPodium
      // },
      {
        name: "dailyQuestions",
        url: "/parents/daily-questions",
        text: "Daily Questions",
        icon: mdiMessageText
      },
      {
        name: "practiceQuestions",
        url: "/parents/practice",
        text: "Practice",
        icon: mdiPuzzleEdit
      },
      {
        name: "stories",
        url: "/parents/stories",
        text: "Stories",
        icon: mdiHumanFemaleGirl
      },
      {
        name: "toyZone",
        text: "Toy Zone",
        icon: mdiToyBrickOutline,
        url: "/parents/digital-store"
      },
      {
        name: "shop",
        text: "Shop",
        icon: mdiCartOutline,
        url: "/shop",
        noLoginRequired: true
      },
      {
        name: "plans",
        url: "/myplan",
        text: "My Plan",
        icon: mdiReceipt,
        noLoginRequired: true
      },
      {
        name: "gift",
        url: "/giftsubscription",
        text: "Gift a Subscription",
        icon: mdiGift,
        noLoginRequired: true
      },
      {
        name: "subscription",
        url: "/parents/subscription",
        text: "Subscription",
        icon: mdiBookSettingsOutline,
        showOnLoggedInOnly: true
      },
      {
        name: "help",
        url: "/contact-us",
        text: "Contact Us",
        icon: mdiHelpCircle,
        noLoginRequired: true
      },
      {
        name: "question_answer",
        url: "/faq",
        text: "FAQ",
        icon: mdiForum,
        noLoginRequired: true
      }
    ],
    drawer: false,
    mini: false,
    right: null,
    profileMenuItems: [
      {
        title: "My Profile",
        route: "/parents/parent-profile",
        name: "profile"
      },
      {
        title: "Achievements",
        route: "/parents/achievements",
        name: "achievements"
      },
      {
        title: "Logout..",
        route: "/",
        name: "logout"
      }
    ],
    navItems: [
      {
        title: "Learning Points",
        route: "/parents/leaderboard",
        name: "learningPoint"
      },
      {
        title: "Pricing",
        route: "/home/#pricing",
        name: "pricing"
      }
    ]
  }),
  watch: {
    isSidebarOpen(v) {
      if (this.$vuetify.breakpoint.smAndDown) this.drawer = v;
    },
    drawer(v) {
      if (!v) this.toggleSidebar();
    }
  },
  computed: {
    ...mapState("global", ["selectedUserProfile", "userType"]),
    isLoggedIn() {
      return this.$auth.loggedIn;
    },
    filteredNavItems() {
      return this.navItems.filter(n => {
        if (n.name == "switchAccount") {
          return this.isLoggedIn == true ? true : false;
        }

        return true;
      });
    }
  },
  methods: {
    goToChildProfile(child) {
      const link = "/parents/child-profile";
      this.setChild(child);
      this.fetchSubjectLevels(child.id);
      if (this.$route.path !== link) {
        this.$router.replace("/parents/child-profile");
      }
    },
    async fetchSubjectLevels(id) {
      if (id) {
        const resp = await this.$api.userSubjectLevel.find({
          user_profile_id: id
        });
        let subjectLevels = {};
        await resp.forEach(obj => {
          subjectLevels[obj.subject_id] = {
            id: obj.level_id,
            level: obj.level
          };
        });
        this.changeSubjectLevels(subjectLevels);
      }
    },
    redirectTo(link) {
      if (link == "/parents/leaderboard") {
        this.onSelect({ name: "/parents/leaderboard" });
      } else {
        this.$router.replace(link);
      }
    },
    goToSelectedPage(menu) {
      // if (menu.name == "help") {
      //   menu.url = this.$auth.loggedIn ? menu.url : "/contact-us";
      // }

      this.onSelect(menu);
    },
    logout() {
      console.log("logout called");
      localStorage.removeItem("vuex");
      this.$auth.logout();
      this.$multiplayerSocket.disconnect();
      //this.$router.replace("/");
    },
    onProfileMenuItemClick(link) {
      console.log("going to call logout");
      switch (link.name) {
        case "profile":
        case "achievements":
          this.$router.replace(link.route);
          break;
        case "logout":
          this.logout();
          break;
        default:
          break;
      }
    },
    onNavLinkClick(link) {
      switch (link.name) {
        case "learningPoint":
          this.onSelect({ name: link.route });
          break;
        case "pricing":
          document.getElementById("pricing").scrollIntoView({
            behavior: "smooth"
          });
          break;
        case "switchAccount":
          break;
        default:
          this.$router.replace(link.route);
          break;
      }
    },
    redirectToLogin() {
      if (this.userType === "Normal") {
        this.redirectTo("/login");
      } else if (this.userType === "Paytm-Mini-User") {
        this.$router.replace("/paytmlanding");
      }
    }
  }
};
