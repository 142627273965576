const SERVICE_NAME = "advertisement";
import backend from "@/lib/backend";

export default axios => ({
  get(controller, params) {
    let data = { controller };
    if (params) {
      Object.assign(data, params);
    }
    return backend(axios, SERVICE_NAME).find(data);
  },
  create(controller, data) {
    let body = { controller };
    if (!data) {
      throw new Error("Missing body!");
    }
    Object.assign(body, data);
    return backend(axios, SERVICE_NAME).create(body);
  },
  update(controller, id, data) {
    let body = { controller };
    if (!data) {
      throw new Error("Missing body!");
    }
    Object.assign(body, data);
    return backend(axios, SERVICE_NAME).patch(id, body);
  },
  delete(controller, id) {
    if (!controller) {
      throw new Error("Missing controller!");
    }
    let url = `${id}?controller=${controller}`;
    return backend(axios, SERVICE_NAME).delete(url);
  }
});
