const SERVICE_NAME = "child-stories-performance";
import backend from "@/lib/backend";
export default axios => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  fetchReportData(childId) {
    console.log('rarar')
    return backend(axios, SERVICE_NAME).find({
        controller: 'report-data',
        childId: childId
    });
  }
});
