
import user_mixin from "~/mixins/user_mixin";
import { mdiMenu, mdiSegment } from "@mdi/js";
export default {
  mixins: [user_mixin],
  props: ["onSelect", "toggleSidebar", "isSidebarOpen"],
  data() {
    return {
      showProfileMenu: false,
      profileMenuItems: [
        {
          title: "My Profile",
          route: "/parents/parent-profile",
          name: "profile"
        },
        {
          title: "Achievements",
          route: "/parents/achievements",
          name: "achievements"
        },
        {
          title: "Logout",
          route: "/",
          name: "logout"
        }
      ],
      navItems: [
        /*
        {
          title: "Learning Points",
          route: "/parents/leaderboard",
          name: "learningPoint"
        },
        */
        {
          title: "Pricing",
          route: "/home/#pricing",
          name: "pricing"
        }
      ],
      icons: {
        mdiMenu,
        mdiSegment
      }
    };
  },
  computed: {
    isLoggedIn() {
      return this.$auth.loggedIn;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isTablet() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    userName() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.parent_name.charAt(0);
      }
      return "";
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("vuex");
      this.$auth.logout();
    },
    goToChildProfile(child) {
      this.setChild(child);
      const link = "/parents/child-profile";
      //this.fetchSubjectLevels(child.id);
      if (this.$route.path !== link) {
        this.$router.replace(link);
      }
    },

    redirectTo(link) {
      switch (link) {
        case "/parents/leaderboard":
          this.onSelect({ name: "/parents/leaderboard" });
          break;
        case "/signup":
          this.$router.replace({
            name: "free-signup"
          });
          break;
        default:
          this.$router.replace(link);
          break;
      }
      /*
      if (link == "/parents/leaderboard") {
        this.onSelect({ name: "/parents/leaderboard" });
      } else {
        this.$router.replace(link);
      }
      */
    },
    logout() {
      this.$auth.logout();
      this.$router.replace("/");
    },
    onProfileMenuItemClick(link) {
      switch (link.name) {
        case "profile":
        case "achievements":
          this.$router.replace(link.route);
          break;
        case "logout":
          this.logout();
          break;
        default:
          break;
      }
    },
    onNavLinkClick(link) {
      console.log("inside onnvabar", link);
      switch (link.name) {
        case "learningPoint":
          this.onSelect({ name: link.route });
          break;
        case "pricing":
          this.navigateSmooth("#choose_plan");
          break;
        case "try_sample":
          this.navigateSmooth("#try_sample");
          break;
        case "switchAccount":
          break;
        default:
          this.$router.replace(link.route);
          break;
      }
    },
    navigateSmooth(id) {
      if (this.$route.path == "/") {
        this.startScroll(id);
      } else {
        this.$router.replace("/");
        setTimeout(() => {
          this.startScroll(id);
        }, 500);
      }
    },
    startScroll(id) {
      const scrollElem = document.querySelector(id).offsetTop;
      document
        .querySelector(".main")
        .scrollTo({ top: scrollElem - 100, behavior: "smooth" });
    }
  }
};
