const SERVICE_NAME = "offer-details";
import backend from "@/lib/backend";
export default axios => ({
  //use $paginate:false in find()
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  get(data) {
    return backend(axios, SERVICE_NAME).get(data);
  }
});
