
import { mapMutations } from "vuex";
import HomeNavbar from "../components/home/HomeNavbar.vue";
import HomeSidebar from "../components/home/HomeSidebar.vue";
import Sidebar from "../components/Sidebar.vue";

export default {
  middleware: ["under-maintenance"],
  scrollToTop: true,
  components: {
    HomeSidebar,
    HomeNavbar,
    Sidebar,
    SignInSignUp: () => import("../components/SignInSignUp.vue")
  },
  head: {
    link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }]
  },
  data() {
    return {
      isSinginSingupModel: false,
      selectedPath: null,
      isSidebarOpen: false,
      hideFooter: false
    };
  },
  methods: {
    ...mapMutations("global", ["setShopCurrency", "setUnregisteredUserId"]),
    onSelectHandler(event) {
      if (this.selectedPath) {
        this.selectedPath = event.name;
      }

      if (!this.$auth.loggedIn && !event.noLoginRequired) {
        this.isSinginSingupModel = true;
      } else {
        this.isSinginSingupModel = false;
        this.$router.push(event.url);
      }
    },
    closeSignInSignUp() {
      this.isSinginSingupModel = false;
    },
    toggleSide() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  },
  async created() {
    this.setUnregisteredUserId();
    const shopCountries = await this.$api.shop.find({
      items: "shopCountries"
    });
    const currency = shopCountries.find(el => el.id === 81);
    this.setShopCurrency(currency);
  },
  mounted() {
    this.hideFooter = this.$route.query.hideFooter;
  }
};
