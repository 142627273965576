const SERVICE_NAME = "plan-rates";
import backend from "@/lib/backend";
export default axios => ({
  //use $paginate:false in find()
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  get(data) {
    return backend(axios, SERVICE_NAME).get(data);
  },
  patch(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, data);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
});
