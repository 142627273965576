
export default {
  props: ["onSelect"],
  data() {
    return {
      items: [
        { name:"dashboard", url:"", text: "My Dashboard", icon: "mdi-home-outline" },
        { name:"levels", url:"", text: "My Levels", icon: "mdi-view-dashboard" },
        { name:"dailyQuestions", url:"", text: "Daily Questions", icon: "mdi-file-document-outline" },
        { name:"practiceQuestions", url:"", text: "Practice Questions", icon: "mdi-folder-outline" },
        { name:"plans", url:"", text: "My Plans", icon: "mdi-account-circle-outline" },
        { name:"reports", url:"", text: "My Reports", icon: "mdi-book-outline" },
        { name:"leaderboard", url:"", text: "Leaderboard", icon: "mdi-chart-bar" },
        { name:"faq", url:"", text: "FAQ", icon: "mdi-tune-variant" },
        { name:"help", url:"", text: "Help", icon: "mdi-help-circle-outline" },
      ],
    };
  },
};
