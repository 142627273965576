
import {
  mdiForumOutline,
  mdiChatQuestion,
  mdiCashMultiple,
  mdiAccountGroup,
  mdiTicketPercentOutline,
  mdiShopping,
  mdiArchive,
  mdiExitToApp,
  mdiPlayNetworkOutline,
  mdiAlertOctagram,
  mdiDevTo
} from '@mdi/js';
import LessonsIcon from '@/components/Lessons/Icon.vue';
export default {
  middleware: ['auth', 'admin-only', 'under-maintenance'],
  components: { LessonsIcon },
  data: () => ({
    dialog: false,
    closable: true,
    selectedItem: 0,
    items: [
      {
        text: 'Questions',
        icon: mdiForumOutline,
        items: [
          {
            text: 'Question Sets Bank',
            url: '/admin/questionSets'
          },
          {
            text: 'Lessons',
            url: '/admin/lessons'
          },
          {
            text: 'Question performance',
            url: '/admin/question-performance'
          },
          {
            text: 'Daily Question performance',
            url: '/admin/daily-question-performance'
          },
          {
            text: 'Wild Card',
            url: '/admin/wild-card'
          }
        ]
      },

      {
        text: 'Daily Questions',
        icon: mdiChatQuestion,
        items: [
          {
            text: 'Question Set Of The Day',
            url: '/admin/question-set-of-the-day'
          },
          {
            text: 'Additional Points',
            url: '/admin/additional-points'
          }
        ]
      },
      {
        text: 'Users',
        icon: mdiAccountGroup,
        items: [
          {
            text: 'Users Details',
            url: '/admin/users'
          },
          {
            text: 'Child Details',
            url: '/admin/active-child'
          },
          {
            text: 'Child Subscriptions',
            url: '/admin/subscriptions/track-subscriptions'
          },
          {
            text: 'Student Performance',
            url: '/admin/leaderboard'
          },
          {
            text: 'Child Question History',
            url: '/admin/question-set-history'
          },
          {
            text: 'Child Monthly Points',
            url: '/admin/child-monthly-points'
          },
          {
            text: 'Child Points History',
            url: '/admin/child-points-history'
          },
          {
            text: 'Child Accuracy',
            url: '/admin/child-accuracy'
          },
          {
            text: 'Child Active History',
            url: '/admin/child-active-history'
          },
          {
            text: 'Child Competition Details',
            url: '/admin/child-competition-details'
          },
          {
            text: 'Child Competition Winner',
            url: '/admin/competition-winner'
          }
        ]
      },
      {
        text: 'Subscriptions',
        icon: mdiCashMultiple,
        url: '/admin/subscriptions'
      },
      {
        text: 'Coupon',
        icon: mdiTicketPercentOutline,
        items: [
          {
            text: 'Coupons',
            url: '/admin/coupons'
          },
          {
            text: 'Coupon Usage',
            url: '/admin/coupons/coupon-usage'
          },
          {
            text: 'Affiliate Inquiries',
            url: '/admin/coupons/affiliate-inquiries'
          }
        ]
      },
      {
        text: 'Shop',
        icon: mdiShopping,
        items: [
          {
            text: 'Masters',
            url: '/admin/shop/masters'
          },
          {
            text: 'Products',
            url: '/admin/shop/products'
          },
          {
            text: 'Offers',
            url: '/admin/shop/offers'
          },
          {
            text: 'Orders',
            url: '/admin/shop/orders'
          },
          {
            text: 'Trades',
            url: '/admin/digital-store/trades'
          }
        ]
      },
      {
        text: 'Config',
        icon: mdiArchive,
        items: [
          {
            text: 'Archive Points',
            url: '/admin/archive-points'
          },
          {
            text: 'LeaderBoard Referal',
            url: '/admin/leaderboard-referal'
          },
          {
            text: 'Shop Order',
            url: '/admin/shop-order'
          }
        ]
      },
      {
        text: 'Stories',
        icon: mdiPlayNetworkOutline,
        url: '/admin/stories'
      },

      {
        text: 'Advertisements',
        icon: mdiAlertOctagram,
        items: [
          { text: 'Ad Units', url: '/admin/advertisements/units' },
          {
            text: 'Ad Specification',
            url: '/admin/advertisements/ad-specification'
          },
          {
            text: 'Advertisers',
            url: '/admin/advertisements/advertisers'
          }
        ]
      },
      {
        text: 'Developers',
        icon: mdiDevTo,
        items: [{ text: 'Encryption/Decryption', url: '/admin/encryption' }]
      }
    ],
    icons: {
      mdiExitToApp
    },
    drawer: true
  }),
  mounted() {
    if (!this.$auth.user.email_verified) {
      // this.$router.replace("/varify-email");
      this.dialog = true;

      let currDate = new Date();
      let userCreationDate = new Date(this.$auth.user.createdAt);

      const timeDiff = Math.abs(
        userCreationDate.getTime() - currDate.getTime()
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (diffDays > 7) {
        this.closable = false;
      }
    }
  },
  methods: {
    resendLink() {
      this.$api.notify
        .sendVarificationMail({ email: this.$auth.user.email })
        .then(rsp => {
          this.snackbarText = rsp.message;
          this.snackbar = true;
          this.dialog = false;
          // alert(rsp.message)
        });
    },
    logout() {
      this.$auth.logout();
      this.$router.replace('/');
    }
  }
};
