const SERVICE_NAME = "assessment";
import backend from "@/lib/backend";
export default axios => ({
  getAssessmentTest(data) {
    const { subjectLevelId, subjectId, attendedSetsId } = data;
    return backend(axios, SERVICE_NAME).find({
      levelId: subjectLevelId,
      subjectId: subjectId,
      attendedSetsId: attendedSetsId
    });
  },
  submitAssessmentTest(data) {
    const {
      questionSets,
      childId,
      subjectId,
      levelId,
      assessed,
      finalAssessmentTest,
      questionsetLimitExceeded,
      isLastAssessmentPassed,
      isQuestionsetLimitExceeded
    } = data;
    return backend(axios, SERVICE_NAME).create({
      questionSets,
      childId,
      subjectId,
      levelId,
      assessed,
      finalAssessmentTest,
      questionsetLimitExceeded,
      isLastAssessmentPassed,
      isQuestionsetLimitExceeded
    });
  }
});
